import classNames from "classnames";
import { ElementType, ReactNode, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { components, ControlProps, MenuListProps, MenuProps, OptionProps } from "react-select";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import { Button, Dropdown, DropdownCommon, HomeIcon, SearchIcon, Select, Separator, Size } from "../../../design-system";
import { Option as BaseOption } from "../../../design-system/components/Input/BaseSelect";
import { CommonLang, useLang } from "../../../lang";
import { useAppContext } from "../../contexts";
import { useToggle } from "../../hooks";

export interface DropdownItem extends BaseOption<string> {
    description?: string;
    avatar?: ElementType | string;
}

const SIZE = {
    sm: "h-10 w-10",
    md: "h-12 w-12"
};

function IconAvatar({ icon, size, dark, className }: { icon: ReactNode; size: Extract<Size, "sm" | "md">; dark?: boolean; className?: string }): JSX.Element {
    return (
        <div
            className={classNames(className, `flex shrink-0 items-center justify-center rounded ${SIZE[size]}`, {
                "bg-slate-light text-white": dark,
                "bg-white": !dark
            })}
            children={icon}
        />
    );
}

function Avatar({ avatar, size = "sm" }: { avatar: ElementType | string; size?: Extract<Size, "sm" | "md"> }): JSX.Element {
    if (typeof avatar === "string") {
        return <IconAvatar icon={<img className="rounded" title="avatar" src={avatar} />} size={size} />;
    }
    const Icon = avatar;
    return <IconAvatar icon={<Icon color="white" size="md" />} size={size} dark />;
}

function Control({ children, ...props }: ControlProps<DropdownItem>) {
    return (
        <components.Control className="cursor-default rounded-none border-none" {...props}>
            <div className="mx-1 my-3 flex w-full cursor-text rounded-md border border-grey-light p-2 text-default" children={children} />
        </components.Control>
    );
}

function Menu(props: MenuProps<DropdownItem>) {
    return <components.Menu {...props} className="!rounded-none border-none shadow-inner" />;
}

function Option(props: OptionProps<DropdownItem>) {
    const { label, description, avatar } = props.data;
    return (
        <components.Option {...props}>
            <div className="my-2 flex items-center gap-2 truncate">
                {avatar && <Avatar avatar={avatar} size="md" />}
                <div className="truncate">
                    <div className="truncate font-bold">{label}</div>
                    <div className="truncate text-xxs font-light">{description}</div>
                </div>
            </div>
        </components.Option>
    );
}

function MenuList({
    props,
    innerButtonOnClick,
    innerButtonText
}: {
    props: MenuListProps<DropdownItem, false>;
    innerButtonOnClick?: () => void;
    innerButtonText?: string;
}): JSX.Element {
    return (
        <components.MenuList className="text-slate-dark" {...props}>
            {props.children}
            {innerButtonOnClick && (
                <>
                    {!!(props?.children as ReactNode[]).length && <Separator />}
                    <div className="flex cursor-default items-center justify-center gap-2 py-4">
                        <Button isLoading={false} onClick={innerButtonOnClick} children={innerButtonText} />
                    </div>
                </>
            )}
        </components.MenuList>
    );
}

function renderMenuList(props: MenuListProps<DropdownItem, false>, innerButtonOnClick?: () => void, innerButtonText?: string) {
    return <MenuList props={props} innerButtonOnClick={innerButtonOnClick} innerButtonText={innerButtonText} />;
}

export default function DropdownMenu<TAppItem extends { id: string }, TAppCode extends string>({
    items,
    isExpanded = true
}: {
    items: DropdownItem[];
    isExpanded?: boolean;
}): JSX.Element {
    const [isOpen, toggleIsOpen, setIsOpen] = useToggle(false);
    const lang = useLang<CommonLang>();
    const navigate = useNavigate();
    const { selectedItem, appInfo } = useAppContext<TAppItem, TAppCode>();

    const item = useMemo(() => {
        if (selectedItem) {
            return items.find(it => it.value === selectedItem.id);
        }
        return items[0];
    }, [items, selectedItem]);

    function onChange(itemId: string) {
        if (!itemId || itemId === "all") {
            navigate(appInfo.baseUrl);
        } else {
            navigate(`${appInfo.baseUrl}/${itemId}`);
        }
        setIsOpen(false);
    }

    function innerButtonOnClickHandler(): void {
        navigate(appInfo.baseUrl);
        setIsOpen(false);
    }

    return (
        <Dropdown open={isOpen} onOpenChange={toggleIsOpen}>
            {isExpanded ? (
                <DropdownCommon.Trigger.WithChevron
                    data-id="dropdown-menu"
                    isOpen={isOpen}
                    children={
                        <div className="flex flex-1 gap-2">
                            {item?.avatar && <Avatar avatar={item.avatar} />}
                            <div className="flex-1 truncate text-white">
                                <div className="truncate text-xs font-light">{lang.app?.itemName}</div>
                                <div className="truncate font-bold">{item?.label || lang.app?.allItemsName}</div>
                            </div>
                        </div>
                    }
                    isDark
                />
            ) : (
                <DropdownCommon.Trigger
                    data-id="dropdown-menu"
                    children={<div className="flex justify-center">{<Avatar avatar={item?.avatar ?? HomeIcon} />}</div>}
                />
            )}
            <DropdownCommon.Content
                align="start"
                children={
                    <Select.Search
                        autoFocus
                        backspaceRemovesValue={false}
                        defaultValue="all"
                        components={{
                            Control,
                            IndicatorSeparator: null,
                            Option,
                            Menu,
                            MenuList: props => renderMenuList(props, innerButtonOnClickHandler, `${lang.shared.seeAllItems(lang.app)} (${items.length - 1})`)
                        }}
                        icon={SearchIcon}
                        controlShouldRenderValue={false}
                        hideSelectedOptions={false}
                        isClearable={false}
                        menuIsOpen
                        onChange={onChange}
                        options={items}
                        placeholder={lang.shared.search}
                        styles={{ menu: () => ({ width: 288 }) }} // needed to remove default style
                        noOptionsMessage={() => ""}
                        tabSelectsValue={false}
                        value={item?.value}
                        menuPosition="absolute"
                        filterOption={(option: FilterOptionOption<DropdownItem>, rawInput: string) => {
                            const input = rawInput.toLowerCase();
                            return (
                                (typeof option.data.label === "string" && option.data.label.toLowerCase().includes(input)) ||
                                option.data.description?.toLowerCase().includes(input) ||
                                false
                            );
                        }}
                    />
                }
            />
        </Dropdown>
    );
}
