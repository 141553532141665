import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import { CommonLang } from "../lang";
dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
dayjs.extend(utc);

// eslint-disable-next-line import/prefer-default-export
export const DEFAULT_DATE_FORMAT = "L"; // Automatically manage language
export const DEFAULT_DATE_TIME_FORMAT = "L LTS"; // Automatically manage language
export const DEFAULT_DATE_MONTH_DAY_YEAR_FORMAT = "LL"; // August 16, 2018

export function formatDateTime(date: Date) {
    return dayjs(date).format(DEFAULT_DATE_TIME_FORMAT);
}
export function formatDate(date: Date) {
    return dayjs(date).format(DEFAULT_DATE_FORMAT);
}
export function formatDateFullText(date: Date) {
    return dayjs(date).format("LLL");
}
export function formatDateFromNow(date: Date) {
    return dayjs(date).fromNow();
}
export function formatUTCDateTime(date: Date) {
    return dayjs(date).utc().format(DEFAULT_DATE_TIME_FORMAT);
}
export function formatUTCDate(date: Date) {
    return dayjs(date).utc().format(DEFAULT_DATE_FORMAT);
}
export function formatDateWithMonthDay(date: Date) {
    return dayjs(date)
        .format(DEFAULT_DATE_MONTH_DAY_YEAR_FORMAT)
        .replaceAll(/, \d{4}/gm, "");
}

const TODAY_DATE = new Date();
export const DEFAULT_DATE = TODAY_DATE.setFullYear(TODAY_DATE.getFullYear() - 30);
const minSelectableDate = new Date();
minSelectableDate.setFullYear(minSelectableDate.getFullYear() - 18);

export const hasLegalAge = (date: Date) => {
    return date <= minSelectableDate;
};

export const startYear = new Date().getFullYear() - 100;
export const endYear = new Date().getFullYear() + 100;
export const dateRange = [startYear, endYear];
export const years = ([start, end]: [number, number]): number[] => {
    return Array.from({ length: end - start + 1 }, (_, i) => start + i);
};

/**
 * It takes several dates, a language object, and an optional boolean, and returns the most recent date well formated
 * @param  - `completionDate, createdDate, updatedDate` - dates to choose from
 * @param {CommonLang} lang - CommonLang - this is the language object that contains the strings for the
 * language you want to use.
 * @param {boolean} [isFeminine] - If the language is gendered, this will determine whether to use the
 * feminine or masculine form of the word.
 * @returns A string
 */
export function displayMostRecentDate(
    {
        doneDate,
        completedDate,
        createdDate,
        updatedDate,
        requestedDate,
        addedDate
    }: {
        doneDate?: Date;
        completedDate?: Date;
        createdDate?: Date;
        updatedDate?: Date;
        requestedDate?: Date;
        addedDate?: Date;
    },
    lang: CommonLang,
    isFeminine?: boolean
): string {
    if (doneDate) {
        return `${lang.date.doneOn(isFeminine)} ${dayjs(doneDate).format("LL")}`;
    }
    if (completedDate) {
        return `${lang.date.completedOn(isFeminine)} ${dayjs(completedDate).format("LL")}`;
    }
    if (updatedDate) {
        return `${lang.date.updatedOn(isFeminine)} ${dayjs(updatedDate).format("LL")}`;
    }
    if (createdDate) {
        return `${lang.date.createdOn(isFeminine)} ${dayjs(createdDate).format("LL")}`;
    }
    if (requestedDate) {
        return `${lang.date.requestedOn(isFeminine)} ${dayjs(requestedDate).format("LL")}`;
    }
    if (addedDate) {
        return `${lang.date.addedOn(isFeminine)} ${dayjs(addedDate).format("LL")}`;
    }
    return "";
}

export function nextDayDate(date?: Date): Date | undefined {
    if (date) {
        return dayjs(date).add(1, "day").toDate();
    }
    return undefined;
}

export function previousDayDate(date?: Date): Date | undefined {
    if (date) {
        return dayjs(date).subtract(1, "day").toDate();
    }
    return undefined;
}

export function formatDurationFromYears(lang: CommonLang, numberOfYears: number) {
    const safeYears = Math.max(numberOfYears, 0);
    const yearsPart = Math.floor(safeYears);
    const monthsPart = Math.floor((safeYears * 12) % 12);
    const monthString = monthsPart ? `${monthsPart}${lang.chart.monthAbbreviation}` : "";
    return `${yearsPart}${lang.chart.yearAbbreviation}${monthString}`;
}
