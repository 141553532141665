import classNames from "classnames";
import ReactMarkdown from "react-markdown";
import { QuestionCircleTwoToneIcon } from "../../../../common";

export default function SurveyQuestionTitle({ questionText, questionIndex }: { questionText: string; questionIndex?: string }): JSX.Element {
    return (
        <span className="flex items-center space-x-2">
            <QuestionCircleTwoToneIcon />
            <div className={classNames({ "flex items-center space-x-2 divide-x": !!questionIndex })}>
                {questionIndex && <div className="text-xs text-gray-300">{questionIndex}</div>}
                <div className="prose">
                    <ReactMarkdown className="pl-2 font-semibold text-primary">{questionText}</ReactMarkdown>
                </div>
            </div>
        </span>
    );
}
