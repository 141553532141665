import { App, isDefined, UserProfile } from "@vaultinum/vaultinum-api";
import _ from "lodash";
import { addPriceToProduct, getLang, getLangCode, getProductList, languages } from "../..";
import { getPrice, getPrices, getPricingPlans } from "./stripeService";

export type LinkInfo = {
    link_type: string;
    target: string;
    url: string;
};

export interface PriceInfo {
    typeKey: App.PricingPlans;
    groupTitle?: string | null;
    title: string;
    subtitle?: string;
    price?: string;
    ctaText?: string;
    ctaUrl?: LinkInfo;
    features?: {
        included: boolean;
        text: string;
    }[];
    unit_amount?: number;
}

export async function getPriceInfo(pricingPlan: App.PricingPlans, userProfile: UserProfile | null): Promise<PriceInfo | undefined> {
    const langCode = getLangCode(userProfile);
    const lang = getLang(langCode, languages);
    const productList = getProductList(lang);
    let product = productList.find((element: PriceInfo) => element.typeKey === pricingPlan);
    if (product && product.features?.length) {
        const price = await getPrice(pricingPlan);
        product = addPriceToProduct(product, pricingPlan, lang, langCode, price);
    }
    return product;
}

export async function getAppPricingList(app: string, userProfile: UserProfile | null = null): Promise<PriceInfo[]> {
    const langCode = getLangCode(userProfile);
    const lang = getLang(langCode, languages);
    const pricingPlans = await getPricingPlans(app);
    const productList = getProductList(lang);
    const prices = await getPrices(app); // get list of prices (from stripe) for the given app

    return _.sortBy(
        productList
            .map(product => {
                const price = prices.find(element => element.metadata.plan === product.typeKey);
                const pricingPlan = pricingPlans?.find(element => element === product.typeKey);
                if (pricingPlan && product.features?.length) {
                    product = addPriceToProduct(product, app, lang, langCode, price);
                    return product?.price ? product : undefined;
                }
                return undefined;
            })
            .filter(isDefined),
        "unit_amount"
    );
}
