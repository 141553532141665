import { App, isDefined } from "@vaultinum/vaultinum-api";
import Stripe from "stripe";
import { CardCommon, CreditCardIcon, IconTag, MastercardIcon, RowCard, RowCardInterface, RowCards, SepaIcon, VisaIcon } from "../../../../design-system";
import { CommonLang, useLang } from "../../../../lang";
import { separateWithBullet } from "../../../helpers";
import { CardBrandEnum } from "../../../services/paymentService";

export interface PaymentMethodDetailInterface {
    paymentMethod: Stripe.PaymentMethod;
    rightChildren?: RowCardInterface["rightChildren"];
    isDefault?: boolean;
}

function renderNumbers(paymentMethod: Stripe.PaymentMethod): string {
    if (paymentMethod.card) {
        return `•••• •••• •••• ${paymentMethod.card.last4}`;
    }
    if (paymentMethod.sepa_debit) {
        return `${paymentMethod.sepa_debit.country}•• ${paymentMethod.sepa_debit.bank_code}••••••${paymentMethod.sepa_debit.last4}`;
    }
    return "";
}

function renderExpirationDate(paymentMethod: Stripe.PaymentMethod): JSX.Element | null {
    const lang = useLang();
    if (paymentMethod.card) {
        const month = String(paymentMethod.card.exp_month).padStart(2, "0");
        const year = String(paymentMethod.card.exp_year).slice(-2);
        return (
            <span title={lang.payment.method.expirationDate}>
                {month}/{year}
            </span>
        );
    }
    return null;
}

function renderDetails(paymentMethod: Stripe.PaymentMethod): JSX.Element {
    const expirationDate = renderExpirationDate(paymentMethod);
    const name = paymentMethod.billing_details.name;
    const email = paymentMethod.billing_details.email;
    const details = [expirationDate, name, ...(paymentMethod.type === App.PaymentMethod.SEPA_DEBIT ? [email] : [])];
    return <div className="text-xs">{details.filter(isDefined).map(separateWithBullet)}</div>;
}

function renderIcon(paymentMethod: Stripe.PaymentMethod): JSX.Element {
    if (paymentMethod.card) {
        switch (paymentMethod.card.brand) {
            case CardBrandEnum.VISA:
                return <VisaIcon size="lg" />;
            case CardBrandEnum.MASTERCARD:
                return <MastercardIcon size="lg" />;
            default:
                return <CreditCardIcon size="lg" color="slate" />;
        }
    }
    if (paymentMethod.sepa_debit) {
        return <SepaIcon size="lg" />;
    }
    return <></>;
}

function isPaymentMethodExpired(paymentMethod: Stripe.PaymentMethod): boolean {
    if (paymentMethod.card) {
        const month = paymentMethod.card.exp_month;
        const year = paymentMethod.card.exp_year;
        const currentMonth = new Date().getMonth() + 1;
        const currentYear = new Date().getFullYear();
        return year < currentYear || (year === currentYear && month < currentMonth);
    }
    return false;
}

export default function PaymentMethodDetail({
    paymentMethod,
    actions,
    radio
}: { paymentMethod: Stripe.PaymentMethod } & Partial<CardCommon.WithActions> & Partial<CardCommon.WithRadio>): JSX.Element {
    const lang = useLang<CommonLang>();
    const isExpired = isPaymentMethodExpired(paymentMethod);
    const sharedProps: RowCardInterface = {
        icon: renderIcon(paymentMethod),
        children: (
            <div className="flex-1">
                {renderNumbers(paymentMethod)}
                {renderDetails(paymentMethod)}
            </div>
        ),
        ...(isExpired ? { color: "red", rightChildren: <IconTag.Danger message={lang.payment.method.expired} /> } : {})
    };

    if (actions?.length) {
        return <RowCards.WithActions {...sharedProps} actions={actions} />;
    }

    if (radio && !isExpired) {
        return <RowCards.WithRadio {...sharedProps} radio={radio} />;
    }

    return <RowCard {...sharedProps} />;
}
