import { QuestionCircleOutlined } from "@ant-design/icons";
import { query, Question, QuestionAnswer, ScoreImpactEvaluation, Section, Survey, SurveyRecord, SurveyVariantRecord } from "@vaultinum/vaultinum-api";
import classNames from "classnames";
import { ReactNode, useMemo, useState } from "react";
import { ChevronRightIcon, displayMostRecentDate, Drawers, IconTag, InfoIcon, message, RowCard, SurveyIcon, Tag, Tooltip, useLang } from "../../../../common";
import { BaseLang } from "../../../lang";
import { EnrichedQuestion, getQuestionIndexes, getSectionQuestionFromId } from "../../tools";
import SurveyFixSection from "./SurveyFixSection";
import SurveyQuestion from "./SurveyQuestion";
import SurveyRecommendationUpdateButton from "./SurveyRecommendationUpdateButton";

type ItemPath = { label: string; section?: Section; question?: Question };

export function TagImpact({ evaluation, onImpactClick }: { evaluation?: ScoreImpactEvaluation; onImpactClick?: (evaluation: ScoreImpactEvaluation) => void }) {
    const lang = useLang();
    switch (evaluation) {
        case ScoreImpactEvaluation.CRITICAL:
            return <Tag type="danger" message={lang.survey.evaluationImpact.critical} onClick={() => onImpactClick?.(ScoreImpactEvaluation.CRITICAL)} />;
        case ScoreImpactEvaluation.HIGH:
            return <Tag type="warning" message={lang.survey.evaluationImpact.high} onClick={() => onImpactClick?.(ScoreImpactEvaluation.HIGH)} />;
        case ScoreImpactEvaluation.LOW:
            return <Tag message={lang.survey.evaluationImpact.low} onClick={() => onImpactClick?.(ScoreImpactEvaluation.LOW)} />;
        default:
            return <></>;
    }
}

export default function SurveyRecommendation({
    question,
    surveyLang,
    surveyRecord,
    surveyVersion,
    onPathClick,
    onImpactClick,
    onTagClick,
    onAnswerUpdate,
    showWhy
}: {
    question: EnrichedQuestion;
    surveyLang: Survey.Lang;
    surveyRecord: SurveyVariantRecord;
    surveyVersion: Survey.Version;
    onPathClick?: (surveyRecord: SurveyRecord, section?: Section, question?: Question) => void;
    onImpactClick?: (evaluation: ScoreImpactEvaluation) => void;
    onTagClick?: (tag: string) => void;
    onAnswerUpdate?: (answer: QuestionAnswer, record: SurveyRecord, surveyVersion: Survey.Version) => Promise<void>;
    showWhy?: boolean;
}): JSX.Element | null {
    const lang = useLang<BaseLang>();
    const options = surveyRecord.questions[question.id].selectedIndex?.map(index => question.options[index]);
    const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

    const recommendationRecord = useMemo(() => (question.hasBeenUpdated && question.liveRecord ? question.liveRecord : surveyRecord), [question, surveyRecord]);
    // Do not display the card if there is no recommendation
    if (options.every(option => !option.context?.fixes?.length)) {
        return null;
    }
    const questionSection = getSectionQuestionFromId(surveyVersion.sections, question.id);
    if (!questionSection) {
        return null;
    }

    const questionIndexes = getQuestionIndexes(surveyVersion);
    const sections = [...query(surveyVersion).getSectionParents(questionSection.section.id), questionSection.section];

    const questionPath: {
        label: string;
        key: string;
        question?: Question;
        section?: Section;
    }[] = [
        { label: surveyLang.survey.title, key: `survey_${surveyVersion.surveyKey}` },
        ...sections.map(section => ({ label: surveyLang.sections[section.id]?.title, section, key: section.id })),
        { label: questionIndexes[question.id], question, section: questionSection.section, key: `${questionSection.section}_${question.id}` }
    ];

    function onClick(path: ItemPath) {
        onPathClick?.(surveyRecord, path.section, path.question);
    }

    const onAnswer = async (answer: QuestionAnswer) => {
        try {
            await onAnswerUpdate?.(answer, surveyRecord, surveyVersion);
        } catch {
            void message.error(lang.survey.updateAnswerError);
        }
    };

    return (
        <RowCard
            header={
                <div className="flex py-2 px-5">
                    <span className="flex items-center gap-2">
                        {questionPath.map<ReactNode>((path, i) => [
                            i > 0 && <ChevronRightIcon color="grey" key={path.label} size="xs" />,
                            <span key={path.key} onClick={() => onClick(path)} className={classNames("text-gray-600", { link: !!onPathClick })}>
                                {path.label}
                            </span>
                        ])}
                        <Tooltip title={surveyLang?.questions[question.id]?.text || "-"}>
                            <span className="ml-2 cursor-pointer" onClick={() => setIsDrawerOpen(true)}>
                                <QuestionCircleOutlined color="slate" />
                            </span>
                        </Tooltip>
                        {showWhy && (
                            <Drawers.WithTabs
                                header={{
                                    title: lang.survey.recommendations,
                                    Icon: SurveyIcon
                                }}
                                children={
                                    <div className="flex flex-col gap-4">
                                        <div className="text-slate-primary text-lg font-bold">{lang.survey.yourInitialAnswer}</div>
                                        <SurveyQuestion
                                            question={question}
                                            focusOnQuestion={question}
                                            record={surveyRecord}
                                            questionIndexes={questionIndexes}
                                            surveyLang={surveyLang}
                                            surveyTitle={surveyLang.survey.title}
                                            readOnly
                                            showSelectedOptionEvals
                                            isPDF
                                        />
                                    </div>
                                }
                                onClose={() => setIsDrawerOpen(false)}
                                isVisible={isDrawerOpen}
                                size="lg"
                                tabs={[
                                    {
                                        icon: InfoIcon,
                                        key: "recommendations",
                                        title: lang.survey.recommendations,
                                        children: (
                                            <>
                                                {options
                                                    .filter(option => !!option.context?.why)
                                                    ?.map(option => (
                                                        <div key={option.id} className="mx-2 my-4 space-x-2 rounded-md bg-neutral-300/20 p-2">
                                                            <InfoIcon color="slate" />
                                                            <span>{surveyLang.options[option.id]?.why}</span>
                                                        </div>
                                                    ))}
                                            </>
                                        )
                                    }
                                ]}
                            />
                        )}
                    </span>
                    {question.hasBeenUpdated && (
                        <div className="ml-auto text-xs italic">
                            {displayMostRecentDate({ updatedDate: recommendationRecord.questions[question.id].lastUpdated }, lang, true)}
                        </div>
                    )}
                </div>
            }
            children={
                <>
                    {options.map(option => (
                        <SurveyFixSection
                            question={question}
                            option={option}
                            key={`fixes_${option.id}`}
                            surveyLang={surveyLang}
                            onImpactClick={onImpactClick}
                            onTagClick={onTagClick}
                        />
                    ))}
                </>
            }
            rightChildren={
                <div className="flex flex-col items-end justify-center space-y-2">
                    {!!onAnswerUpdate && !question.isObsolete && (
                        <SurveyRecommendationUpdateButton
                            question={question}
                            questionIndexes={questionIndexes}
                            surveyLang={surveyLang}
                            recommendationRecord={recommendationRecord}
                            onAnswer={onAnswer}
                        />
                    )}
                    {question.isObsolete && (
                        <span title={lang.survey.obsoleteReason}>
                            <IconTag.Warning message={lang.survey.isObsolete} />
                        </span>
                    )}
                </div>
            }
        />
    );
}
