import { createContext, useContext, useMemo } from "react";
import { SubMenuItemProps } from "./SubMenuItem";
import { DropdownActionProps } from "../../../design-system";
import { MenuItem } from "./LeftNavBar";

export interface NavigationMenuContextProps<TOption = any> {
    activeMenuItemKey?: string;

    setMenuItems: (menuItems?: MenuItem[][]) => void;
    setSubMenuItems: (menuItemKey: string, subMenuItems: SubMenuItemProps[] | undefined) => void;

    selectOptions?: { value: TOption; label: string }[];
    setSelectOptions: (selectedOptions: { value: TOption; label: string }[]) => void;

    selectedOption?: TOption;

    setOptionsActions: (actions: DropdownActionProps[]) => void;
}

const Context = createContext<NavigationMenuContextProps>({} as NavigationMenuContextProps);

export function NavigationMenuContextProvider({ children, value }: { children: React.ReactNode; value: NavigationMenuContextProps }) {
    const exposed = useMemo((): NavigationMenuContextProps => value, [value]);
    return <Context.Provider value={exposed} children={children} />;
}

export function useNavigationMenuContext<TOption = unknown>(): NavigationMenuContextProps<TOption> {
    return useContext(Context);
}
