import { Question, Survey } from "@vaultinum/vaultinum-api";
import { ElementType } from "react";
import { CheckCircleIcon, ChevronUpDoubleIcon, ChevronUpIcon, ChevronUpTripleIcon, Tag } from "../../../../common";

function evaluationTagType(evaluation: Question.Evaluation): "success" | "warning" | "danger" | "accent" | undefined {
    switch (evaluation) {
        case Question.Evaluation.Positive:
            return "success";
        case Question.Evaluation.Warning:
            return "warning";
        case Question.Evaluation.High:
            return "accent";
        case Question.Evaluation.Critical:
            return "danger";
        default:
            return undefined;
    }
}

function evaluationTagIcon(evaluation: Question.Evaluation): ElementType | undefined {
    switch (evaluation) {
        case Question.Evaluation.Positive:
            return CheckCircleIcon;
        case Question.Evaluation.Warning:
            return ChevronUpIcon;
        case Question.Evaluation.High:
            return ChevronUpDoubleIcon;
        case Question.Evaluation.Critical:
            return ChevronUpTripleIcon;
        default:
            return undefined;
    }
}

function formatEvaluation(evalTag: Question.TagEvaluation, surveyLang: Survey.Lang): string {
    return surveyLang.evaluationTag[evalTag.tag].name;
}

function OptionEvaluationTags({
    option,
    surveyLang,
    onTagClick
}: {
    option: Question.Option;
    surveyLang: Survey.Lang;
    onTagClick?: (tag: string) => void;
}): JSX.Element {
    return (
        <div className="flex flex-wrap gap-2">
            {[...option.evaluations]
                .filter(evalTag => ![Question.Evaluation.None, Question.Evaluation.NotApplicable].includes(evalTag.evaluation))
                .filter(evalTag => surveyLang.evaluationTag[evalTag.tag])
                .sort((a, b) => formatEvaluation(a, surveyLang).localeCompare(formatEvaluation(b, surveyLang)))
                .map(evalTag => (
                    <Tag
                        key={`${option.id}_${evalTag.tag}_${evalTag.evaluation}`}
                        type={evaluationTagType(evalTag.evaluation)}
                        icon={evaluationTagIcon(evalTag.evaluation)}
                        isOutlined
                        message={formatEvaluation(evalTag, surveyLang)}
                        onClick={() => onTagClick?.(evalTag.tag)}
                        size="sm"
                    />
                ))}
        </div>
    );
}

export default OptionEvaluationTags;
