import { FullAudit, Question, QuestionAnswer, ScoreImpactEvaluation, Section, Survey, SurveyRecord } from "@vaultinum/vaultinum-api";
import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { Badge, Menu, Select, plural, useLang } from "../../../../common";
import { BaseLang } from "../../../lang";
import {
    QuestionsBySurveyKey,
    RecommendationStatus,
    ScopedSurveyVersions,
    countAllAndScopeQuestions,
    groupQuestionsByScope,
    groupQuestionsBySurveyKey
} from "../../tools";
import { TagImpact } from "./SurveyRecommendation";
import SurveyRecommendationsList from "./SurveyRecommendationsList";

function ScopeLabel({ title, count }: { title: string; count: number }): JSX.Element {
    return (
        <div className="flex items-center" title={title}>
            <span className="truncate pr-2">{title}</span>
            <span className="ml-auto">
                <Badge children={count} size="small" />
            </span>
        </div>
    );
}

function Filters({
    lang,
    changePriority,
    affects,
    changeAffect,
    surveyTags,
    selectedScope,
    questions,
    filterOnStatus,
    selectedScoreImpactEvaluation,
    status,
    liveRecords,
    totalCount
}: {
    lang: BaseLang;
    changePriority: (priority: ScoreImpactEvaluation) => void;
    affects: string[];
    changeAffect: (affect: string[]) => void;
    surveyTags: {
        value: string;
        label: string;
    }[];
    selectedScope: string | undefined;
    questions: QuestionsBySurveyKey;
    filterOnStatus: (value: RecommendationStatus) => void;
    selectedScoreImpactEvaluation?: ScoreImpactEvaluation;
    status?: RecommendationStatus;
    liveRecords?: boolean;
    totalCount?: number;
}): JSX.Element {
    const resultCount = selectedScope
        ? groupQuestionsByScope(questions)[selectedScope]?.length
        : Object.keys(questions).flatMap(key => questions[key].questions).length;
    return (
        <div className="flex flex-wrap items-center gap-3 px-4">
            <div className="flex w-1/5 items-center space-x-3">
                <Select.Basic
                    options={Object.values(ScoreImpactEvaluation).map(evaluation => ({ value: evaluation, label: <TagImpact evaluation={evaluation} /> }))}
                    onChange={changePriority}
                    value={selectedScoreImpactEvaluation}
                    placeholder={lang.shared.everything}
                    label={lang.survey.priority}
                    className="w-full"
                    isClearable
                />
            </div>
            <div className="flex w-1/3 items-center space-x-3">
                <Select.Multiple
                    options={surveyTags}
                    value={affects}
                    onChange={changeAffect}
                    placeholder={lang.shared.everything}
                    label={lang.survey.affects}
                    className="w-full"
                />
            </div>
            {liveRecords && (
                <div className="flex w-1/5 items-center space-x-3">
                    <Select.Basic
                        options={Object.values(RecommendationStatus).map(value => ({ label: value, value }))}
                        onChange={filterOnStatus}
                        value={status}
                        placeholder={lang.shared.everything}
                        label={lang.survey.status}
                        className="w-full"
                    />
                </div>
            )}
            <span className="text-slate-light font-semibold whitespace-nowrap pt-4">{plural(lang.shared.resultsWithTotal, resultCount, totalCount)}</span>
        </div>
    );
}

export default function SurveyRecommendations({
    liveRecords,
    surveyLangs,
    surveyRecords,
    scopedSurveyVersions,
    disclaimer,
    onPathClick,
    onAnswerUpdate,
    showWhy,
    scope,
    displayMenu = true // Will be removed after full audit view will be deleted from admin app
}: {
    surveyLangs: Survey.Lang[];
    surveyRecords: SurveyRecord[];
    scopedSurveyVersions: ScopedSurveyVersions;
    disclaimer?: ReactNode;
    liveRecords?: SurveyRecord[];
    onPathClick?: (surveyRecord: SurveyRecord, section?: Section, question?: Question) => void;
    onAnswerUpdate?: (answer: QuestionAnswer, record: SurveyRecord, surveyVersion: Survey.Version) => Promise<void>;
    showWhy?: boolean;
    scope?: FullAudit.Scope;
    displayMenu?: boolean;
}): JSX.Element | null {
    const lang = useLang<BaseLang>();
    const [selectedScoreImpactEvaluation, setSelectedScoreImpactEvaluation] = useState<ScoreImpactEvaluation>();
    const [affects, setAffects] = useState<string[]>([]);
    const [status, setStatus] = useState<RecommendationStatus>();
    const [selectedScope, setSelectedScope] = useState<string | undefined>(scope);

    const { all, scopes } = countAllAndScopeQuestions(surveyLangs, surveyRecords, scopedSurveyVersions);

    useEffect(() => {
        setSelectedScope(scope);
    }, [scope]);

    const { questions, surveyTags } = useMemo(
        (): {
            questions: QuestionsBySurveyKey;
            surveyTags: { value: string; label: string }[];
        } =>
            groupQuestionsBySurveyKey(surveyLangs, surveyRecords, scopedSurveyVersions, {
                affects,
                status,
                liveRecords,
                impactScoreEvaluation: selectedScoreImpactEvaluation
            }),
        [scopedSurveyVersions, surveyLangs, selectedScoreImpactEvaluation, surveyRecords, affects, status, liveRecords]
    );

    const menuItems: {
        [scope: string]: number;
    } = useMemo(
        () => ({
            [lang.shared.everything]: all,
            ...scopes
        }),
        [questions]
    );

    function changePriority(newEvaluation: ScoreImpactEvaluation) {
        setSelectedScoreImpactEvaluation(newEvaluation);
    }

    function changeAffect(newAffects: string[]) {
        setAffects(newAffects);
    }

    const onImpactClick = useCallback(
        (newEvaluation: ScoreImpactEvaluation) => {
            setSelectedScoreImpactEvaluation(existingEvaluation => (existingEvaluation === newEvaluation ? undefined : newEvaluation));
        },
        [selectedScoreImpactEvaluation]
    );

    function filterOnStatus(value: RecommendationStatus) {
        setStatus(value);
    }

    const onTagClick = useCallback(
        (tag: string) => {
            setAffects(existingAffets => (existingAffets.includes(tag) ? existingAffets.filter(affect => affect !== tag) : [...existingAffets, tag]));
        },
        [affects]
    );

    return (
        <div className="flex h-full flex-col space-y-2 gap-4">
            <Filters
                lang={lang}
                changePriority={changePriority}
                affects={affects}
                changeAffect={changeAffect}
                surveyTags={surveyTags}
                questions={questions}
                selectedScope={selectedScope}
                filterOnStatus={filterOnStatus}
                selectedScoreImpactEvaluation={selectedScoreImpactEvaluation}
                status={status}
                totalCount={scope ? scopes[scope] : all}
            />
            <div className="flex h-full w-full flex-col overflow-hidden md:flex-row">
                {displayMenu && (
                    <div className="overflow-auto md:w-1/6">
                        <Menu
                            className="divide-y"
                            selectedKeys={selectedScope ? [selectedScope] : []}
                            mode="inline"
                            onClick={({ key }) => setSelectedScope(key)}
                        >
                            {Object.keys(menuItems).map(key => (
                                <Menu.Item key={key} className="my-0 px-2">
                                    <ScopeLabel title={key ?? lang.shared.everything} count={menuItems[key]} />
                                </Menu.Item>
                            ))}
                        </Menu>
                    </div>
                )}
                <div className="flex flex-col space-y-2">
                    <div className="px-4 text-xs">{disclaimer}</div>
                    <div className="w-full overflow-auto">
                        <SurveyRecommendationsList
                            questions={questions}
                            selectedScope={selectedScope}
                            onImpactClick={onImpactClick}
                            onPathClick={onPathClick}
                            onTagClick={onTagClick}
                            onAnswerUpdate={onAnswerUpdate}
                            showWhy={showWhy}
                            allLabel={lang.shared.everything}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
