import { isDefined } from "@vaultinum/vaultinum-api";
import classNames from "classnames";
import { groupBy, sum } from "lodash";
import { ComponentProps, ReactNode } from "react";
import { formatDateWithMonthDay } from "../../../helpers";
import { COLOR_SCHEME, Color } from "../../referentials";
import { CheckCircleFilledIcon, ClockCircleIcon } from "../Icons";
import { Tooltip } from "../Tooltip";

export type TimelineEventProps = { date: Date; label?: ReactNode };
export type TimelineProps = { events: [...TimelineEventProps[], TimelineEventProps, TimelineEventProps]; color?: Color };

function getBorderColor(isBeforeToday: boolean, color?: Color): string {
    if (color) {
        return isBeforeToday ? COLOR_SCHEME[color].primary : COLOR_SCHEME.slate.dark;
    }
    return COLOR_SCHEME.slate.dark;
}

function Event({ event, color, position }: { event: TimelineEventProps; color?: Color; position?: "start" | "middle" | "end" }): JSX.Element {
    const isBeforeToday = new Date() > event.date;
    return (
        <div className="w-24 relative">
            <Tooltip
                placement="bottom"
                title={
                    position === "middle" && (
                        <>
                            {event.label}
                            <span className="text-xs italic">{formatDateWithMonthDay(event.date)}</span>
                        </>
                    )
                }
                asChild
            >
                <div
                    className={classNames("flex", {
                        "justify-start text-left": position === "start",
                        "justify-center text-center": position === "middle",
                        "justify-end text-right": position === "end"
                    })}
                >
                    {position === "middle" && (
                        <>
                            <div
                                className="absolute border-l-2 -mt-3 h-2"
                                style={{
                                    borderColor: getBorderColor(isBeforeToday, color)
                                }}
                            />
                            <div className="hidden md:block">
                                {isBeforeToday ? <CheckCircleFilledIcon color={color || "green"} /> : <ClockCircleIcon color="grey" />}
                            </div>
                        </>
                    )}
                    {position !== "middle" && (
                        <span>
                            {event.label}
                            <span className="text-xs italic">{formatDateWithMonthDay(event.date)}</span>
                        </span>
                    )}
                </div>
            </Tooltip>
        </div>
    );
}

function calculateTodayProgression(dates: Date[]): number {
    const todayDate = Date.now();
    const firstDate = dates[0].getTime();
    const lastDate = dates[dates.length - 1].getTime();
    if (todayDate < firstDate || lastDate === firstDate) {
        return 0;
    } else if (todayDate >= lastDate) {
        return 100;
    }
    // Calculate the time differences in milliseconds
    return ((todayDate - firstDate) / (lastDate - firstDate)) * 100;
}

function calculateDateGaps(dates: Date[]): number[] {
    // Calculate the time differences in milliseconds
    const timeDifferences = [];
    for (let i = 0; i < dates.length - 1; i++) {
        const diff = dates[i + 1].getTime() - dates[i].getTime();
        timeDifferences.push(diff);
    }
    // Calculate the total time difference in milliseconds
    const totalDifference = sum(timeDifferences);
    // Calculate the percentage gaps
    return timeDifferences.map(diff => Math.round((diff / totalDifference) * 100));
}

function mergeEventsByDate(events: TimelineEventProps[]): TimelineEventProps[] {
    const sortedEvents = [...events].sort((a, b) => a.date.getTime() - b.date.getTime());
    const groupedByDate = groupBy(sortedEvents, obj => obj.date);
    return Object.values(groupedByDate).map(group => ({
        date: group[0].date,
        label: (
            <div className="flex flex-col w-max">
                {group.map((event, i) => (
                    <span key={i}>{event.label}</span>
                ))}
            </div>
        )
    }));
}

function getPositionByIndex(index: number, eventsLength: number): ComponentProps<typeof Event>["position"] {
    if (index === 0) {
        return "start";
    }
    if (index === eventsLength - 1) {
        return "end";
    }
    return "middle";
}

export default function Timeline({ events, color = "green" }: TimelineProps): JSX.Element | null {
    const eventsByDate = mergeEventsByDate(events);

    if (eventsByDate.length < 2) {
        return null;
    }

    const dates = eventsByDate.map(event => event.date).filter(isDefined);
    const gaps = calculateDateGaps(dates);
    const progression = calculateTodayProgression(dates);
    return (
        <div className="flex flex-col gap-1">
            <div className="relative flex w-full h-2 bg-grey-light rounded-full" />
            <div style={{ width: `${progression}%`, backgroundColor: COLOR_SCHEME[color].light }} className="relative flex h-2 rounded-full -mt-3" />
            <div className="flex w-full">
                {eventsByDate?.map((event, index) => (
                    <div key={event.date.toString()} style={{ width: `${gaps[index]}%` }} className="h-full">
                        <Event event={event} color={color} position={getPositionByIndex(index, eventsByDate.length)} />
                    </div>
                ))}
            </div>
        </div>
    );
}
