import {
    FullAudit,
    isDefined,
    Note,
    query,
    Question,
    QuestionAnswer,
    QuestionAnswerModifier,
    StaffUser,
    Survey,
    SurveyVariantRecord
} from "@vaultinum/vaultinum-api";
import classnames from "classnames";
import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import { Location } from "react-router-dom";
import {
    Buttons,
    Drawers,
    formatName,
    FormIcon,
    InfoIcon,
    Input,
    isDomainOwner,
    Radio,
    ScrollableDiv,
    SurveyIcon,
    useAuthContext,
    useLang,
    useWhiteLabelContext
} from "../../../../common";
import { BaseLang } from "../../../lang";
import { addNewSurveyRecordNote, archiveSurveyRecordNote, updateSurveyRecordNote } from "../../services";
import { EnrichedFullAudit } from "../../tools";
import { NoteDrawer } from "../note";
import SurveyContributor from "./SurveyContributor";
import SurveyFixSection from "./SurveyFixSection";
import "./SurveyQuestion.css";
import SurveyQuestionOption from "./SurveyQuestionOption";
import SurveyQuestionTitle from "./SurveyQuestionTitle";

export default function SurveyQuestion({
    question,
    record,
    onAnswer,
    questionIndexes,
    surveyLang,
    surveyTitle,
    fullAudit,
    currentAccountUserId,
    onlyShowSelectedOptions,
    initialAnswer,
    focusOnQuestion,
    className,
    readOnly,
    showSelectedOptionEvals,
    isPDF,
    location,
    hideSubQuestions,
    staffUser,
    notes,
    onAnswerSelected,
    selectedQuestionOptions,
    enableNotes = true,
    showFilter
}: {
    question: Question;
    questionIndexes: Record<string, string>;
    record: SurveyVariantRecord;
    surveyLang: Survey.Lang;
    fullAudit?: EnrichedFullAudit;
    currentAccountUserId?: string;
    className?: string;
    focusOnQuestion?: Question;
    hideSubQuestions?: boolean;
    isPDF?: boolean;
    location?: Location;
    onlyShowSelectedOptions?: boolean;
    initialAnswer?: number[];
    readOnly?: boolean;
    showSelectedOptionEvals?: boolean;
    surveyTitle?: string;
    onAnswer?: (answer: QuestionAnswer) => void;
    staffUser?: StaffUser;
    notes?: FullAudit.SurveyRecordNote[];
    onAnswerSelected?: (answer: QuestionAnswer) => void;
    selectedQuestionOptions?: Question.Option[];
    enableNotes?: boolean;
    showFilter?: boolean;
}) {
    const lang = useLang<BaseLang>();
    const { claims } = useAuthContext();
    const { whiteLabelDomain } = useWhiteLabelContext();
    const questionNotes = notes?.filter(note => note.target.questionId === question.id);
    const answer = record.questions[question.id];
    const selectedOptions = selectedQuestionOptions || answer ? answer.selectedIndex.map(index => question.options[index]) : [];
    const originallySelectedOptions = initialAnswer ? initialAnswer.map(index => question.options[index]) : [];

    const onSelectedRadioOption = (optionIndex: number) => {
        const selectedAnswer = {
            questionId: question.id,
            selectedIndex: [optionIndex],
            modifiers: {}
        };
        if (onAnswerSelected) {
            onAnswerSelected(selectedAnswer);
        } else {
            onAnswer?.(selectedAnswer);
        }
    };
    const onSelectedCheckboxOption = (optionIndex: number, checked: boolean) => {
        const selectedIndex = answer?.selectedIndex ?? [];
        onAnswer?.({
            questionId: question.id,
            selectedIndex: checked ? [...selectedIndex, optionIndex] : selectedIndex.filter(index => index !== optionIndex),
            modifiers: answer?.modifiers ?? {}
        });
    };

    const onUpdateModifier = (optionIndex: number, modifiers?: QuestionAnswerModifier) => {
        const selectedIndex = answer?.selectedIndex ?? [];
        onAnswer?.({
            questionId: question.id,
            selectedIndex: selectedIndex.includes(optionIndex) ? selectedIndex : [...selectedIndex, optionIndex],
            modifiers: modifiers ?? {}
        });
    };

    const questionContent = (
        <div
            className={classnames({
                prose: !isPDF
            })}
        >
            <ReactMarkdown className="text-base font-bold">{surveyLang.questions[question.id]?.text}</ReactMarkdown>
        </div>
    );
    const questionId = location ? new URLSearchParams(location.search).get("questionId") : null;
    const [showNotes, setShowNotes] = useState<boolean>(false);
    const [showDetails, setShowDetails] = useState<boolean>(false);

    function closeDetailsDrawer() {
        setShowDetails(false);
    }

    function openDetailsDrawer() {
        setShowDetails(true);
    }

    function closeNotesDrawer() {
        setShowNotes(false);
    }

    function openNotesDrawer() {
        setShowNotes(true);
    }

    async function onNoteSave(text: string, visibility: Note.Visibility, noteId?: string) {
        const userUID = staffUser ? staffUser.uid : currentAccountUserId;
        if (!userUID) {
            throw new Error(lang.shared.saveErrorMessage);
        }
        if (!noteId) {
            await addNewSurveyRecordNote(record.fullAuditId, userUID, text, visibility, question.id, record.id);
        } else {
            await updateSurveyRecordNote(record.fullAuditId, userUID, text, noteId);
        }
    }

    async function onNoteDelete(noteId: string) {
        const userUID = staffUser ? staffUser.uid : currentAccountUserId;
        if (!userUID) {
            throw new Error(lang.shared.saveErrorMessage);
        }
        await archiveSurveyRecordNote(record.fullAuditId, userUID, noteId);
    }

    const fullAuditUsers = [
        ...(fullAudit?.reviewerAccounts?.flatMap(reviewerAccount => reviewerAccount.users) || []),
        ...(fullAudit?.auditedAccount?.users || []),
        ...(fullAudit?.beneficiaryAccount?.users || [])
    ].filter(isDefined);

    return (
        <div
            className={classnames("survey-question", className, {
                "space-y-4": !isPDF
            })}
            id={question.id}
        >
            <div
                className={classnames("question-content group relative", {
                    active: questionId === question.id,
                    "p-2": !isPDF
                })}
            >
                {surveyTitle && <div className="text-xs text-gray-300">{surveyTitle}</div>}
                <div
                    className={classnames("flex items-start", {
                        "mb-2 pr-10": !isPDF
                    })}
                >
                    <div className={"mr-1 mt-1 text-xs text-gray-300"}>{questionIndexes[question.id]}&nbsp;|</div>
                    {isPDF ? (
                        <ScrollableDiv linesToShow={5} isPDF>
                            {questionContent}
                        </ScrollableDiv>
                    ) : (
                        <div className="pr-16">{questionContent}</div>
                    )}
                </div>
                {question.optionsType === Question.OptionType.Radio && question.options.length > 1 && (
                    <Radio.Group
                        onValueChange={(value: string) => onSelectedRadioOption(parseInt(value))}
                        value={question.options
                            .filter(option => !onlyShowSelectedOptions || selectedOptions.some(({ id }) => option.id === id))
                            .findIndex(option => selectedOptions.some(({ id }) => option.id === id))
                            .toString()}
                        className="flex w-full flex-col"
                    >
                        {question.options
                            .filter(option => !onlyShowSelectedOptions || selectedOptions.some(({ id }) => option.id === id))
                            .map((option, index) => {
                                const isInitialAnswer = !!originallySelectedOptions.find(selectedOption => selectedOption.id === option.id);
                                return (
                                    <React.Fragment key={option.id}>
                                        <Radio.Item id={option.id} value={index.toString()}>
                                            <Input.Radio
                                                label={
                                                    <SurveyQuestionOption
                                                        option={option}
                                                        selected={selectedOptions.some(({ id }) => option.id === id)}
                                                        answer={answer}
                                                        onUpdateModifier={modifier => onUpdateModifier(index, modifier)}
                                                        disabled={readOnly}
                                                        surveyLang={surveyLang}
                                                        hideModifiers={onlyShowSelectedOptions || readOnly}
                                                        showSelectedOptionEvals={showSelectedOptionEvals}
                                                        forceModifierDisplay={question.options.length === 1}
                                                        isInitialAnswer={isInitialAnswer}
                                                    />
                                                }
                                                data-id="survey-answer"
                                                id={option.id}
                                                disabled={readOnly}
                                                name={question.id}
                                                checked={selectedOptions.some(({ id }) => option.id === id)}
                                                onClick={() =>
                                                    onAnswer &&
                                                    selectedOptions.some(({ id }) => option.id === id) &&
                                                    onAnswer({
                                                        questionId: question.id,
                                                        selectedIndex: [],
                                                        modifiers: {}
                                                    })
                                                }
                                            />
                                        </Radio.Item>
                                        {showFilter && selectedOptions.some(({ id }) => option.id === id) && (
                                            <SurveyFixSection question={question} option={option} surveyLang={surveyLang} showTagImpact={false} />
                                        )}
                                    </React.Fragment>
                                );
                            })}
                    </Radio.Group>
                )}
                {question.optionsType === Question.OptionType.Radio && question.options.length === 1 && (
                    <SurveyQuestionOption
                        option={question.options[0]}
                        selected={selectedOptions.includes(question.options[0])}
                        answer={answer}
                        onUpdateModifier={modifier => onUpdateModifier(0, modifier)}
                        disabled={!!readOnly}
                        surveyLang={surveyLang}
                        hideModifiers={onlyShowSelectedOptions || readOnly}
                        forceModifierDisplay
                    />
                )}
                {question.optionsType === Question.OptionType.Checkbox && (
                    <div className="flex flex-col">
                        {question.options
                            .filter(option => !onlyShowSelectedOptions || selectedOptions.some(({ id }) => option.id === id))
                            .map((option, index) => {
                                const isInitialAnswer = !!originallySelectedOptions.find(selectedOption => selectedOption.id === option.id);
                                return (
                                    <Input.Checkbox
                                        key={option.id}
                                        onChange={e => onSelectedCheckboxOption(index, e.target.checked)}
                                        disabled={readOnly}
                                        checked={selectedOptions.some(({ id }) => option.id === id)}
                                        id={option.id}
                                        label={
                                            <SurveyQuestionOption
                                                option={option}
                                                selected={selectedOptions.some(({ id }) => option.id === id)}
                                                answer={answer}
                                                onUpdateModifier={modifier => onUpdateModifier(index, modifier)}
                                                disabled={readOnly}
                                                surveyLang={surveyLang}
                                                hideModifiers={onlyShowSelectedOptions}
                                                showSelectedOptionEvals={showSelectedOptionEvals}
                                                forceModifierDisplay={question.options.length === 1}
                                                isInitialAnswer={isInitialAnswer}
                                            />
                                        }
                                    />
                                );
                            })}
                    </div>
                )}
                <div className="absolute right-1.5 top-1.5 flex items-center space-x-1">
                    {!!fullAuditUsers.length && answer && (
                        <div className="question-contributor">
                            <SurveyContributor fullAuditUsers={fullAuditUsers} currentAccountUserId={currentAccountUserId} answer={answer} />
                        </div>
                    )}
                    <NoteDrawer
                        onClose={closeNotesDrawer}
                        onSave={onNoteSave}
                        onDelete={onNoteDelete}
                        isReviewer={
                            !!fullAudit?.reviewerAccounts
                                ?.flatMap(reviewerAccount => reviewerAccount?.users)
                                ?.find(user => user?.id === currentAccountUserId) ||
                            !!staffUser ||
                            isDomainOwner(claims, whiteLabelDomain?.id)
                        }
                        notes={questionNotes?.map(note => {
                            const user = fullAuditUsers.find(fullAuditUser => fullAuditUser.id === note.createdByUID);
                            return {
                                ...note,
                                userName: user ? formatName(user) : "-"
                            };
                        })}
                        header={<SurveyQuestionTitle questionText={surveyLang.questions[question.id]?.text} questionIndex={questionIndexes[question.id]} />}
                        accountName={fullAudit?.auditedAccount?.organisation?.companyName}
                        isVisible={showNotes}
                    />
                    <Drawers.WithTabs
                        header={{
                            title: lang.shared.details,
                            Icon: SurveyIcon
                        }}
                        children={<SurveyQuestionTitle questionText={surveyLang.questions[question.id]?.text} questionIndex={questionIndexes[question.id]} />}
                        tabs={[
                            {
                                icon: InfoIcon,
                                key: "detail",
                                title: lang.shared.details,
                                children: (
                                    <div className="m-4 space-y-4">
                                        <div className="prose prose-sm">
                                            <ReactMarkdown>{surveyLang.questions[question.id]?.description || ""}</ReactMarkdown>
                                        </div>
                                    </div>
                                )
                            }
                        ]}
                        isVisible={showDetails}
                        onClose={closeDetailsDrawer}
                    />

                    {!isPDF && enableNotes && (
                        <div
                            className={classnames({
                                "hidden group-hover:flex": !questionNotes?.length,
                                flex: questionNotes?.length
                            })}
                        >
                            <Buttons.Details
                                icon={FormIcon}
                                onClick={openNotesDrawer}
                                label={lang.accountInformation.notes.title}
                                alwaysDisplay={!!questionNotes?.length}
                                isLoading={false}
                            />
                        </div>
                    )}
                    {!isPDF && (
                        <>
                            {surveyLang.questions[question.id]?.description && (
                                <Buttons.Details icon={InfoIcon} onClick={openDetailsDrawer} isLoading={false} />
                            )}
                        </>
                    )}
                </div>
            </div>
            {!hideSubQuestions &&
                selectedOptions
                    .filter(
                        selectedOption => selectedOption.questions?.filter(subQuestion => !record || query(subQuestion).isIncludedWithVariant(record)).length
                    )
                    .map(selectedOption => (
                        <div
                            key={selectedOption.id}
                            className={classnames("sub-questions", {
                                "mt-1": !isPDF
                            })}
                        >
                            {selectedOption.questions
                                ?.filter(subQuestion => !record || query(subQuestion).isIncludedWithVariant(record))
                                .filter(
                                    subQuestion =>
                                        !focusOnQuestion || subQuestion.id === focusOnQuestion.id || query(subQuestion).hasSubQuestion(focusOnQuestion.id)
                                )
                                .map(subQuestion => (
                                    <SurveyQuestion
                                        key={subQuestion.id}
                                        question={subQuestion}
                                        record={record}
                                        onAnswer={onAnswer}
                                        questionIndexes={questionIndexes}
                                        surveyLang={surveyLang}
                                        onlyShowSelectedOptions={onlyShowSelectedOptions}
                                        focusOnQuestion={focusOnQuestion}
                                        readOnly={readOnly}
                                        showSelectedOptionEvals={showSelectedOptionEvals}
                                        isPDF={isPDF}
                                        location={location}
                                        fullAudit={fullAudit}
                                        currentAccountUserId={currentAccountUserId}
                                        staffUser={staffUser}
                                        notes={notes}
                                        showFilter={showFilter}
                                    />
                                ))}
                        </div>
                    ))}
        </div>
    );
}
