import { AnalysedCodeRepository, FileInfo, getDepth, MetaFileInfo, sum } from "@vaultinum/vaultinum-api";
import { Color } from "../design-system";

export type CodeRepositoryInfo = Partial<AnalysedCodeRepository> & { name: string; rootFolderPaths?: string[] };

export type FileTreeRecord<T extends FileInfo> = T & {
    isFolder: boolean;
    depth?: number;
    repository?: string;
    isAtSelectedDepth?: boolean;
    children?: FileTreeRecord<T>[];
    renamed?: string;
};

export function getFolderColor(record: FileTreeRecord<FileInfo & MetaFileInfo>): Color | undefined {
    if (record.isAtSelectedDepth || record.is?.test) {
        return "green";
    }
    if (record.is?.generated) {
        return "red";
    }
    if (record.path.includes("/src/")) {
        return "blue";
    }
    return undefined;
}

// Sum all children stats and set it to the parent folder
function aggregateFolderSums<T extends FileInfo & MetaFileInfo>(rootNode: FileTreeRecord<T>): FileTreeRecord<T> {
    rootNode.children?.forEach(child => {
        if (child.children) {
            aggregateFolderSums(child); // ensure child sums are updated first
            rootNode.lines = sum(rootNode.lines ?? 0, child.lines ?? 0);
            rootNode.size = sum(rootNode.size ?? 0, child.size ?? 0);
        }
    });
    rootNode.is = {
        binary: rootNode.children?.every(child => child.is?.binary),
        generated: rootNode.children?.every(child => child.is?.generated),
        test: rootNode.children?.every(child => child.is?.test),
        conf: rootNode.children?.every(child => child.is?.conf),
        doc: rootNode.children?.every(child => child.is?.doc),
        lang: rootNode.children?.every(child => child.is?.lang),
        thirdParty: rootNode.children?.every(child => child.is?.thirdParty)
    };
    return rootNode;
}

function createFolderRecord<T extends FileInfo>(name: string, path: string, depth: number, codeRepository: CodeRepositoryInfo): FileTreeRecord<T> {
    return {
        path,
        name,
        depth,
        repository: codeRepository.name,
        isFolder: true,
        children: [] as FileTreeRecord<T>[],
        lines: 0,
        size: 0,
        isAtSelectedDepth: codeRepository.rootFolderPaths?.some(rootFolder => [rootFolder, `${codeRepository.name}${rootFolder}`].includes(path)) ?? false
    } as FileTreeRecord<T>;
}

export function mapFilesToRecord<T extends FileInfo>(files: FileInfo[], root: FileTreeRecord<T>, codeRepository: CodeRepositoryInfo): FileTreeRecord<T> {
    files.forEach(file => {
        const parts = file.path.split("/").slice(1); // Remove the empty first element from splitting
        let current = root;

        // loop over filepath parts to identify the parent directories
        for (let i = 0; i < parts.length; i++) {
            const name = parts[i];
            if (!current.children) {
                current.children = [];
            }
            const depth = getDepth(file);
            if (i === parts.length - 1) {
                const path = `${codeRepository.name}/${parts.join("/")}`;
                if (name) {
                    const record: FileTreeRecord<T> = {
                        ...file,
                        path,
                        name,
                        repository: codeRepository.name,
                        size: file.size ?? 0,
                        lines: file.lines ?? 0,
                        depth,
                        isFolder: false
                    } as FileTreeRecord<T>;
                    current.children.push(record);
                    // Aggregate lines and size at the file's parent directory
                    current.lines = sum(current.lines ?? 0, file.lines ?? 0);
                    current.size = sum(current.size ?? 0, file.size ?? 0);
                }
            } else {
                // Directory
                const path = `${codeRepository.name}/${parts.slice(0, i + 1).join("/")}/`;
                let folder: FileTreeRecord<T> | undefined = current.children.find(child => child.path === path);
                // Create a new folder record
                if (!folder) {
                    folder = createFolderRecord(name, path, depth, codeRepository);
                    current.children.push(folder);
                }
                current = folder;
            }
        }
    });
    return root;
}

export function buildTree<T extends FileInfo>(files: T[], codeRepository?: CodeRepositoryInfo): FileTreeRecord<T> {
    if (!codeRepository) {
        return {} as FileTreeRecord<T>;
    }
    const rootFolder = createFolderRecord<T>(codeRepository.name, `${codeRepository.name}/`, 0, codeRepository);
    const records = mapFilesToRecord(files, rootFolder, codeRepository);
    return aggregateFolderSums(records);
}
