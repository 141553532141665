import { CheckIcon, CrossCircleFilledIcon, InfoCircleTwoToneIcon, WarningIcon } from "../../Icons";
import { AlertProps, BaseAlert } from "../BaseAlert";

export namespace Alert {
    export function Info(props: Omit<AlertProps, "className" | "icon">) {
        return <BaseAlert data-id="alert-info" className="border-blue-light bg-white" icon={<InfoCircleTwoToneIcon size="md" isTwoToneColor />} {...props} />;
    }

    export function Success(props: Omit<AlertProps, "className" | "icon">) {
        return (
            <BaseAlert data-id="alert-success" className="border-green-light bg-green-extra-light" icon={<CheckIcon size="md" color="green" />} {...props} />
        );
    }

    export function Warning(props: Omit<AlertProps, "className" | "icon">) {
        return (
            <BaseAlert
                data-id="alert-warning"
                className="border-orange-light bg-orange-extra-light"
                icon={<WarningIcon size="md" color="orange" />}
                {...props}
            />
        );
    }

    export function Danger(props: Omit<AlertProps, "className" | "icon">) {
        return (
            <BaseAlert
                data-id="alert-danger"
                className="border-red-light bg-red-extra-light"
                icon={<CrossCircleFilledIcon size="md" color="red" />}
                {...props}
            />
        );
    }
}
