import { Question, ScoreImpactEvaluation, Survey } from "@vaultinum/vaultinum-api";
import { Tooltip, useLang } from "../../../../common";
import { EnrichedQuestion } from "../../tools";
import OptionEvaluationTags from "./OptionEvaluationTags";
import { TagImpact } from "./SurveyRecommendation";

export default function SurveyFixSection({
    question,
    option,
    surveyLang,
    onImpactClick,
    onTagClick,
    showTagImpact = true
}: {
    question: EnrichedQuestion;
    option: Question.Option;
    surveyLang: Survey.Lang;
    onImpactClick?: (evaluation: ScoreImpactEvaluation) => void;
    onTagClick?: (tag: string) => void;
    showTagImpact?: boolean;
}): JSX.Element {
    const lang = useLang();
    return (
        <div className="space-y-4">
            {showTagImpact && (
                <div className="flex flex-wrap gap-2">
                    <Tooltip title={`${question.answerScore} / ${question.questionScore}`}>
                        <TagImpact evaluation={question.evaluation} onImpactClick={onImpactClick} />
                    </Tooltip>
                    <OptionEvaluationTags option={option} surveyLang={surveyLang} onTagClick={onTagClick} />
                </div>
            )}
            <div className="space-y-1">
                {option.context?.fixes?.map(fix => (
                    <div key={`${option.id}_${fix.id}`} className="space-x-2">
                        <span className="font-semibold">{fix.type === Question.FixType.LONG ? lang.survey.longFix : lang.survey.quickFix}:</span>
                        <span>{surveyLang.options[option.id]?.fixes?.[fix.id]}</span>
                    </div>
                ))}
            </div>
        </div>
    );
}
