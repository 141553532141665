/* eslint-disable import/no-unused-modules */
import classNames from "classnames";
import { isArray, isString } from "lodash";
import { ElementType, Fragment, ReactNode } from "react";

export namespace SummaryProps {
    export type Cell = { label: ElementType | string; children: ReactNode };
    export type Row = Cell | Cell[];
}

function Label({ label }: Pick<SummaryProps.Cell, "label">): JSX.Element {
    const sharedClasses = "py-2 pr-8";
    if (isString(label)) {
        return <td title={label} className={classNames(sharedClasses, "truncate text-grey-primary")} children={label} />;
    }
    const Icon = label;
    return <td className={sharedClasses} children={<Icon color="grey" />} />;
}

function Children({ children, isSingleCell, isLast = true }: Pick<SummaryProps.Cell, "children"> & { isSingleCell?: boolean; isLast?: boolean }): JSX.Element {
    return <td {...(isSingleCell && { colSpan: 100 })} className={classNames("py-2 text-dark", { "pr-16": !isLast })} children={children} />;
}

function hasMultipleCells(row: SummaryProps.Row): row is SummaryProps.Cell[] {
    return isArray(row);
}

function getRowId(row: SummaryProps.Row): string | undefined {
    return !hasMultipleCells(row) ? getCellId(row) : undefined;
}

function getCellId(cell: SummaryProps.Cell): string | undefined {
    return isString(cell.label) ? cell.label : undefined;
}

export default function Summary({ rows }: { rows: SummaryProps.Row[] }) {
    return (
        <table>
            <tbody
                children={rows.map((row, i) => (
                    <tr key={getRowId(row) ?? `row_${i}`}>
                        {hasMultipleCells(row) ? (
                            row.map((cell, j, cells) => (
                                <Fragment key={getCellId(cell) ?? `cell_${j}`}>
                                    <Label label={cell.label} />
                                    <Children children={cell.children} isLast={j === cells.length - 1} />
                                </Fragment>
                            ))
                        ) : (
                            <>
                                <Label label={row.label} />
                                <Children children={row.children} isSingleCell />
                            </>
                        )}
                    </tr>
                ))}
            />
        </table>
    );
}
