import { LoadingOutlined } from "@ant-design/icons";
import { Input } from "antd";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { CheckCircleIcon, CrossCircleIcon } from "../Icons";

enum Keys {
    BACKSPACE_KEY = "Backspace",
    DELETE_KEY = "Delete",
    TAB_KEY = "Tab",
    LEFT_ARROW_KEY = "ArrowLeft",
    RIGHT_ARROW_KEY = "ArrowRight",
    V_KEY = "KeyV"
}

export default function CodeInput({
    codeLength,
    validateCode,
    codeValidationMessage,
    codeValidationMessageError,
    codeValidationMessageSuccess,
    initialCode,
    className
}: {
    codeLength: number;
    validateCode: (codeInput: string) => Promise<void>;
    codeValidationMessage: string;
    codeValidationMessageError: string;
    codeValidationMessageSuccess: string;
    initialCode?: string;
    className?: string;
}): JSX.Element {
    const [code, setCode] = useState<(number | undefined)[]>(initialCode ? [...initialCode.replace(/\D+/g, "")] : new Array(codeLength));

    function onCodeChanged(index: number, digit?: number) {
        if (index >= codeLength) {
            return;
        }
        setCode(prevState => Object.assign([], prevState, { [index]: digit }));
    }

    const inputRefs = useRef<HTMLInputElement[]>([]);
    const [isInvalid, setIsInvalid] = useState<boolean | undefined>(undefined);
    const [isDisabled, setIsDisabled] = useState<boolean>(false);

    function changeCodeFocus(index: number): void {
        inputRefs.current[index]?.focus();
    }

    function onPaste(event: React.ClipboardEvent<HTMLInputElement>, index: number): void | undefined {
        const value = event.clipboardData.getData("text");
        const digits = [...value.replace(/\D+/g, "")];
        if (isDisabled) {
            return;
        }
        for (const [i, digit] of digits.entries()) {
            onCodeChanged(index + i, Number(digit));
            if (index + i < codeLength - 1) {
                changeCodeFocus(index + i + 1);
            }
        }
        event.preventDefault();
    }

    function onKeyDown(event: React.KeyboardEvent<HTMLInputElement>, index: number): void {
        const keyboardKeyCode = event.nativeEvent.code;
        const digit = event.nativeEvent.key;
        if (digit.match(/\d/g)) {
            onCodeChanged(index, Number(digit));
            if (index < codeLength - 1) {
                changeCodeFocus(index + 1);
            }
        } else {
            if (keyboardKeyCode === Keys.BACKSPACE_KEY) {
                if (code[index] === undefined) {
                    changeCodeFocus(index - 1);
                    onCodeChanged(index - 1);
                }
                onCodeChanged(index);
            }
            if (keyboardKeyCode === Keys.DELETE_KEY) {
                if (code[index] === undefined) {
                    changeCodeFocus(index + 1);
                    onCodeChanged(index + 1);
                }
                onCodeChanged(index);
            }
            if (keyboardKeyCode === Keys.LEFT_ARROW_KEY || (keyboardKeyCode === Keys.TAB_KEY && event.shiftKey)) {
                changeCodeFocus(index - 1);
            }
            if (keyboardKeyCode === Keys.RIGHT_ARROW_KEY || (keyboardKeyCode === Keys.TAB_KEY && !event.shiftKey)) {
                changeCodeFocus(index + 1);
            }
            if (keyboardKeyCode === Keys.V_KEY && (event.ctrlKey || event.metaKey)) {
                return;
            }
        }
        event.preventDefault();
    }

    const validate = async (): Promise<void> => {
        try {
            setIsDisabled(true);
            await validateCode(code.join(""));
            setIsInvalid(false);
        } catch {
            setIsInvalid(true);
        } finally {
            setIsDisabled(false);
        }
    };

    useEffect(() => {
        void (async function () {
            if (code.filter(value => value !== undefined).length === codeLength) {
                setIsInvalid(false);
                await validate();
            }
            if (code.filter(value => value !== undefined).length !== 0) {
                setIsInvalid(undefined);
            }
        })();
    }, [code]);

    return (
        <div className="space-y-2">
            <div className={classNames(className, "flex justify-center")}>
                {Array.from({ length: codeLength }, (_, index) => (
                    <Input
                        key={index}
                        ref={el => {
                            if (el?.input) {
                                inputRefs.current[index] = el.input;
                            }
                        }}
                        onKeyDown={event => onKeyDown(event, index)}
                        onPaste={event => onPaste(event, index)}
                        value={code[index]}
                        inputMode="numeric"
                        disabled={isDisabled}
                        className={classNames(
                            "m-2 h-20 w-12 text-center text-3xl",
                            { "border-danger": isInvalid },
                            { "border-success": isInvalid === false },
                            { "mr-8": index + 1 === codeLength / 2 }
                        )}
                    />
                ))}
            </div>
            {isDisabled && (
                <div className="flex justify-center space-x-2 text-sm">
                    <div>
                        <LoadingOutlined className="text-primary" />
                    </div>
                    <div>{codeValidationMessage}</div>
                </div>
            )}
            {isInvalid && (
                <div className="flex justify-center space-x-2 text-sm text-danger">
                    <div>
                        <CrossCircleIcon color="red" />
                    </div>
                    <div>{codeValidationMessageError}</div>
                </div>
            )}
            {isInvalid === false && ( // value needs to be checked against "false" as can also be "undefined"
                <div className="flex justify-center space-x-2 text-sm text-success">
                    <div>
                        <CheckCircleIcon color="green" />
                    </div>
                    <div>{codeValidationMessageSuccess}</div>
                </div>
            )}
        </div>
    );
}
