import { Question, QuestionAnswer, QuestionAnswerModifier, Survey } from "@vaultinum/vaultinum-api";
import { getData } from "country-list";
import { debounce } from "lodash";
import { useState } from "react";
import { Input, Select, Tag, useLang } from "../../../../common";
import { BaseLang } from "../../../lang";
import { EXTENSION_LIST, TRADEMARK_CLASSES } from "../../tools/SurveyTools";
import OptionEvaluationTags from "./OptionEvaluationTags";

const OriginalAnswerTag = () => {
    const lang = useLang();
    return (
        <div className="float-right pl-3">
            <Tag message={lang.survey.initialAnswer} type="info" size="sm" isOutlined />
        </div>
    );
};

export default function SurveyQuestionOption({
    option,
    forceModifierDisplay,
    selected,
    answer,
    onUpdateModifier,
    disabled,
    surveyLang,
    hideModifiers,
    isInitialAnswer,
    showSelectedOptionEvals
}: {
    option: Question.Option;
    selected: boolean;
    onUpdateModifier: (modifiers?: QuestionAnswerModifier) => void;
    answer?: QuestionAnswer;
    disabled?: boolean;
    forceModifierDisplay?: boolean;
    hideModifiers?: boolean;
    isInitialAnswer?: boolean;
    showSelectedOptionEvals?: boolean;
    surveyLang: Survey.Lang;
}) {
    const [textInput, setTextInput] = useState(answer?.modifiers?.textInput ?? "");
    const debouncedOnUpdateModifier = debounce(onUpdateModifier, 1000);
    const lang = useLang<BaseLang>();

    const getOptionText = (text: string): string => {
        if (!text) {
            return "";
        }
        const optionText = text.trim();
        const endsWithTextBetweenParenthesis = /\([^)]+\)$/.test(optionText);
        // Remove possible "(please upload)" text until "verified assessment" feature is working
        if (endsWithTextBetweenParenthesis && option.modifiers?.fileUpload) {
            return optionText.substring(0, optionText.lastIndexOf("("));
        }
        if (endsWithTextBetweenParenthesis || (!option.modifiers?.textInput && !option.modifiers?.fileUpload)) {
            return optionText;
        }
        if (option.modifiers?.textInput && !endsWithTextBetweenParenthesis) {
            return `${optionText} ${lang.survey.pleaseDescribe}`;
        }
        return optionText;
    };

    function handleDebounce(e: React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLInputElement>) {
        setTextInput(e.target.value);
        if (e.target.value.trim() === "") {
            debouncedOnUpdateModifier();
        } else {
            debouncedOnUpdateModifier({ textInput: e.target.value });
        }
    }

    return (
        <>
            <div className="text-left">
                {getOptionText(surveyLang.options[option.id]?.text)}
                {showSelectedOptionEvals && isInitialAnswer && <OriginalAnswerTag />}
                {showSelectedOptionEvals && selected && <OptionEvaluationTags option={option} surveyLang={surveyLang} />}
            </div>
            {option.modifiers?.extensionList && (
                <Select.Creatable
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                    menuPortalTarget={document.body}
                    className="mb-2"
                    options={EXTENSION_LIST}
                    placeholder={lang.survey.domainExtension}
                    value={answer?.modifiers?.extensionList}
                    onChange={(values: string[]) => {
                        if (!values) {
                            onUpdateModifier();
                        } else {
                            onUpdateModifier({ extensionList: values });
                        }
                    }}
                    isDisabled={disabled}
                />
            )}
            {option.modifiers?.trademarkClassList && (
                <span onClick={e => e.preventDefault()}>
                    <Select.Multiple
                        menuPortalTarget={document.body}
                        className="mb-2"
                        name={option.id}
                        options={TRADEMARK_CLASSES}
                        placeholder={lang.survey.trademarkClass}
                        value={answer?.modifiers?.trademarkClassList}
                        onChange={(values: string[]) => {
                            if (!values.length) {
                                onUpdateModifier();
                            } else {
                                onUpdateModifier({ trademarkClassList: values });
                            }
                        }}
                        isDisabled={disabled}
                    />
                </span>
            )}
            {option.modifiers?.countryList && (selected || forceModifierDisplay) && (
                <span onClick={e => e.preventDefault()}>
                    <Select.Multiple
                        menuPortalTarget={document.body}
                        className="mb-2"
                        name={option.id}
                        options={getData()
                            .sort((a, b) => a.name.localeCompare(b.name))
                            .map(item => ({ value: item.name, label: item.name }))}
                        placeholder={lang.survey.countryList}
                        value={answer?.modifiers?.countryList}
                        onChange={(values: string[]) => {
                            if (!values.length) {
                                onUpdateModifier();
                            } else {
                                onUpdateModifier({ countryList: values });
                            }
                        }}
                        isDisabled={disabled}
                    />
                </span>
            )}
            {(option.modifiers?.textInput || option.modifiers?.fileUpload) && (selected || forceModifierDisplay) && (
                <div className="option-user-input mb-2">
                    {(!hideModifiers || textInput) &&
                        option.modifiers?.textInput &&
                        (forceModifierDisplay ? (
                            <Input.TextArea
                                value={textInput}
                                onChange={handleDebounce}
                                maxLength={1000}
                                disabled={hideModifiers || disabled}
                                displayCount
                                required
                            />
                        ) : (
                            <Input.Text
                                value={textInput}
                                onChange={handleDebounce}
                                required
                                disabled={hideModifiers || disabled}
                                placeholder={lang.survey.extraInformation}
                            />
                        ))}
                </div>
            )}
        </>
    );
}
