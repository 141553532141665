import { InfoIcon } from "../../../../common";

export default function SurveySectionDescription({ description }: { description: string }): JSX.Element {
    return (
        <div className="w-full border-b">
            <span className="flex items-center space-x-2 p-3 font-semibold italic text-secondary">
                <InfoIcon color="slate" />
                <span>{description}</span>
            </span>
        </div>
    );
}
