import { FULL_AUDIT_COLLECTION, FULL_AUDIT_NOTES_COLLECTION, FullAudit, Note } from "@vaultinum/vaultinum-api";
import {
    DocumentData,
    DocumentReference,
    DocumentSnapshot,
    FieldValue,
    QueryConstraint,
    addDoc,
    collection,
    doc,
    getFirestore,
    onSnapshot,
    query,
    serverTimestamp,
    updateDoc,
    where
} from "../../../common";

function getNotesCollection(fullAuditId: string): string {
    return `${FULL_AUDIT_COLLECTION}/${fullAuditId}/${FULL_AUDIT_NOTES_COLLECTION}`;
}

export async function addNewSurveyRecordNote(
    fullAuditId: string,
    createdByUID: string,
    text: string,
    visibility: Note.Visibility,
    questionId: string,
    surveyRecordId: string
): Promise<DocumentReference<DocumentData>> {
    const creationDate = serverTimestamp();
    const note: Omit<FullAudit.SurveyRecordNote, "id" | "creationDate" | "lastUpdated"> & { creationDate: FieldValue; lastUpdated: FieldValue } = {
        creationDate,
        fullAuditId,
        lastUpdated: creationDate,
        createdByUID,
        updatedByUID: createdByUID,
        target: {
            type: FullAudit.Note.TargetType.SURVEY_RECORD,
            surveyRecordId,
            questionId
        },
        visibility,
        text,
        archived: false
    };
    return addDoc(collection(getFirestore(), getNotesCollection(fullAuditId)), note);
}

export async function updateSurveyRecordNote(fullAuditId: string, updatedByUID: string, text: string, noteId: string) {
    await updateDoc(doc(collection(getFirestore(), getNotesCollection(fullAuditId)), noteId), {
        lastUpdated: serverTimestamp(),
        updatedByUID,
        text
    });
}

export async function archiveSurveyRecordNote(fullAuditId: string, updatedByUID: string, noteId: string) {
    await updateDoc(doc(collection(getFirestore(), getNotesCollection(fullAuditId)), noteId), {
        lastUpdated: serverTimestamp(),
        updatedByUID,
        archived: true
    });
}

const docToNote = (snapshot: DocumentSnapshot): FullAudit.SurveyRecordNote => ({
    ...(snapshot.data() as Omit<FullAudit.SurveyRecordNote, "creationDate" | "lastUpdated">),
    id: snapshot.id,
    creationDate: snapshot.data()?.creationDate?.toDate(),
    lastUpdated: snapshot.data()?.lastUpdated?.toDate()
});

export function getSurveyRecordNotes(
    fullAuditId: string,
    onUpdate: (notes: FullAudit.SurveyRecordNote[]) => void,
    surveyRecordId?: string,
    isStaffUser?: boolean
) {
    let queries: QueryConstraint[] = [
        where("archived", "==", false),
        where("visibility", "in", [Note.Visibility.PUBLIC, ...(isStaffUser ? [Note.Visibility.REVIEWERS] : [])])
    ];
    if (surveyRecordId) {
        queries = queries.concat(where("target.surveyRecordId", "==", surveyRecordId));
    }

    return onSnapshot(
        query(collection(getFirestore(), getNotesCollection(fullAuditId)), ...queries),
        querySnapshot => onUpdate(querySnapshot.docs.map(docToNote)),
        () => onUpdate([])
    );
}
