import { FullAuditUser, QuestionAnswerDeed } from "@vaultinum/vaultinum-api";
import { Badge, ContributorBadge, useLang } from "../../../../common";

export default function SurveyContributor({
    fullAuditUsers,
    currentAccountUserId,
    answer
}: {
    fullAuditUsers?: FullAuditUser[];
    currentAccountUserId?: string;
    answer?: QuestionAnswerDeed;
}): JSX.Element | null {
    const lang = useLang();

    if (!fullAuditUsers?.length || !answer?.userUID || answer?.userUID === currentAccountUserId) {
        return null;
    }
    const accountContributor = fullAuditUsers.find(user => user.id === answer?.userUID);

    if (!accountContributor) {
        return <Badge size="small" children="V" type="success" title={lang.survey.updatedByReviewer} />;
    }
    return <ContributorBadge contributor={accountContributor} />;
}
