import { Question, QuestionAnswer, ScoreImpactEvaluation, Section, Survey, SurveyRecord } from "@vaultinum/vaultinum-api";
import { useMemo } from "react";
import { List } from "../../../../common";
import { EnrichedQuestion, QuestionsBySurveyKey, getAllQuestions, orderByEvaluationAndDelta } from "../../tools";
import SurveyRecommendation from "./SurveyRecommendation";

export default function SurveyRecommendationsList({
    questions,
    allLabel,
    selectedScope,
    onImpactClick,
    onPathClick,
    onTagClick,
    onAnswerUpdate,
    showWhy
}: {
    questions: QuestionsBySurveyKey;
    allLabel: string;
    selectedScope?: string;
    onImpactClick?: (priority: ScoreImpactEvaluation) => void;
    onPathClick?: (surveyRecord: SurveyRecord, section?: Section, question?: Question) => void;
    onTagClick?: (tag: string) => void;
    onAnswerUpdate?: (answer: QuestionAnswer, record: SurveyRecord, surveyVersion: Survey.Version) => Promise<void>;
    showWhy?: boolean;
}): JSX.Element | null {
    const dataSource = useMemo(() => {
        const allQuestions = getAllQuestions(questions);
        return orderByEvaluationAndDelta(
            selectedScope && selectedScope !== allLabel ? allQuestions.filter(element => element.scope === selectedScope) : allQuestions
        );
    }, [selectedScope, questions]);

    function renderRecommendation(question: EnrichedQuestion) {
        if (!question.key) {
            return null;
        }
        return (
            <div className="w-full px-4 pb-2">
                <SurveyRecommendation
                    question={question}
                    surveyLang={questions[question.key].surveyLang}
                    surveyRecord={questions[question.key].surveyRecord}
                    surveyVersion={questions[question.key].surveyVersion}
                    onPathClick={onPathClick}
                    onImpactClick={onImpactClick}
                    onTagClick={onTagClick}
                    onAnswerUpdate={onAnswerUpdate}
                    showWhy={showWhy}
                />
            </div>
        );
    }

    return <List<EnrichedQuestion> list={dataSource} render={renderRecommendation} />;
}
