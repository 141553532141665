import { Question, QuestionAnswer, Survey, SurveyVariantRecord } from "@vaultinum/vaultinum-api";
import { useState } from "react";
import { Button, Modal, useLang, useModal } from "../../../../common";
import { BaseLang } from "../../../lang";
import { EnrichedQuestion } from "../../tools";
import SurveyQuestion from "./SurveyQuestion";

export default function SurveyRecommendationUpdateButton({
    question,
    questionIndexes,
    surveyLang,
    recommendationRecord,
    onAnswer
}: {
    question: EnrichedQuestion;
    questionIndexes: Record<string, string>;
    surveyLang: Survey.Lang;
    recommendationRecord: SurveyVariantRecord;
    onAnswer?: (answer: QuestionAnswer) => void;
}): JSX.Element | null {
    const lang = useLang<BaseLang>();
    const [record, setRecord] = useState(recommendationRecord);
    const { isOpen, doOpen, doClose } = useModal();
    const [selectedOptions, setSelectedOptions] = useState<Question.Option[]>();
    const [updatedAnswer, setUpdatedAnswer] = useState<QuestionAnswer>();

    function selectOption(answer: QuestionAnswer) {
        setRecord(prev => {
            return {
                ...prev,
                questions: {
                    ...prev.questions,
                    [question.id]: {
                        ...prev.questions[question.id],
                        selectedIndex: answer.selectedIndex
                    }
                }
            };
        });
        setSelectedOptions(record.questions[question.id].selectedIndex.map(index => question.options[index]));
    }

    function resetOption() {
        setRecord(prev => {
            return {
                ...prev,
                questions: {
                    ...prev.questions,
                    [question.id]: {
                        ...prev.questions[question.id],
                        selectedIndex: recommendationRecord.questions[question.id].selectedIndex
                    }
                }
            };
        });
        setSelectedOptions(recommendationRecord.questions[question.id].selectedIndex.map(index => question.options[index]));
    }

    function openUpdateModal() {
        resetOption();
        doOpen();
    }

    function closeUpdateModal() {
        resetOption();
        doClose();
    }

    function onAnswerSelected(answer: QuestionAnswer) {
        selectOption(answer);
        setUpdatedAnswer(answer);
    }

    function confirmUpdateAnswer() {
        if (updatedAnswer && onAnswer) {
            onAnswer(updatedAnswer);
        }
    }

    return (
        <div className="flex items-center gap-3">
            <Button onClick={openUpdateModal} isLoading={false} type={question.hasBeenUpdated ? "default" : "primary"}>
                {lang.survey.updateAnswer}
            </Button>
            <Modal onConfirm={confirmUpdateAnswer} isOpen={isOpen} onClose={closeUpdateModal} title={lang.survey.updateYourCurrentAnswer} lang={lang} size="lg">
                <SurveyQuestion
                    question={question}
                    focusOnQuestion={question}
                    record={record}
                    questionIndexes={questionIndexes}
                    surveyLang={surveyLang}
                    surveyTitle={surveyLang.survey.title}
                    onAnswerSelected={answer => onAnswerSelected(answer)}
                    selectedQuestionOptions={selectedOptions}
                    isPDF
                />
            </Modal>
        </div>
    );
}
