import { Account } from "@vaultinum/vaultinum-api";
import { useEffect, useRef, useState } from "react";
import { Controller, FieldValues, Path, PathValue, UseFormReturn } from "react-hook-form";
import { BaseInputs, Input, Select, message } from "../../../design-system";
import { useLang } from "../../../lang";
import { getAccountsByEmailAndDomain } from "../../services";

interface EmailInputWithAccountSelectProps<T extends FieldValues> {
    form: UseFormReturn<T>;
    title: string;
    name: string;
    whiteLabelDomainId: string | null;
    disabled?: boolean;
    required?: boolean;
    onLoading?: (loading: boolean) => void;
    onInputClear?: () => void;
}

export default function EmailInputWithAccountSelect<T extends FieldValues>({
    form,
    title,
    name,
    whiteLabelDomainId,
    disabled,
    required,
    onLoading,
    onInputClear,
    onDebounceStart,
    onDebounceEnd
}: EmailInputWithAccountSelectProps<T> & Pick<BaseInputs.Props, "onDebounceStart" | "onDebounceEnd">): JSX.Element {
    const lang = useLang();
    const { getValues, setValue, trigger, control, watch } = form;
    const [accounts, setAccounts] = useState<Account[] | undefined>([]);
    const getPath = (path: string): Path<T> => `${name}.${path}` as Path<T>;
    const [email, setEmail] = useState<string>(getValues(getPath("email")));
    const emailInput = watch(getPath("email"));
    const prevEmailRef = useRef(email);

    useEffect(() => {
        if (email !== prevEmailRef.current) {
            setValue(getPath("accountId"), "" as PathValue<T, Path<T>>);
            setAccounts([]);
        }
    }, [email]);

    useEffect(() => {
        void (async function () {
            if (getValues(getPath("email")) && (await trigger(getPath("email")))) {
                onLoading?.(true);
                try {
                    setAccounts(undefined);
                    const accountsForEmail = await getAccountsByEmailAndDomain(getValues(getPath("email")), whiteLabelDomainId);
                    setAccounts(accountsForEmail);
                } catch (error) {
                    void message.error(lang.shared.failedFetchingAccount);
                    setAccounts([]);
                } finally {
                    onLoading?.(false);
                }
            }
        })();
    }, [emailInput, whiteLabelDomainId]);

    return (
        <div className="flex w-full gap-4">
            <Controller
                name={getPath("email")}
                control={control}
                render={({ field }) => (
                    <Input.Text
                        {...field}
                        value={email}
                        onChange={e => {
                            setEmail(e.target.value);
                            onDebounceStart?.();
                        }}
                        required={required}
                        label={title}
                        placeholder={lang.shared.email}
                        debounce={{
                            wait: 300,
                            callback: e => {
                                if (e.target.value.length) {
                                    setValue(getPath("email"), e.target.value as PathValue<T, Path<T>>);
                                } else {
                                    onInputClear?.();
                                }
                            }
                        }}
                        disabled={disabled}
                        className="w-1/2"
                        onDebounceEnd={onDebounceEnd}
                    />
                )}
            />
            <Controller
                name={getPath("accountId")}
                control={control}
                render={({ field }) => (
                    <Select.Search
                        {...field}
                        label={title}
                        isDisabled={!accounts?.length || disabled}
                        options={(accounts || []).map((account: { id: string; companyName: string }) => ({
                            value: account.id,
                            label: account.companyName
                        }))}
                        placeholder={lang.shared.selectAccount}
                        className="w-1/2"
                        isLoading={accounts === undefined}
                    />
                )}
            />
        </div>
    );
}
