import { FullAudit, query, QuestionAnswer, Section, StaffUser, Survey, SurveyVariantRecord } from "@vaultinum/vaultinum-api";
import { useMemo } from "react";
import { Location } from "react-router-dom";
import { EnrichedFullAudit, getSectionDescription } from "../../tools";
import SurveyQuestion from "./SurveyQuestion";
import SurveySectionTemplate from "./SurveySectionTemplate";

function SectionContent({
    section,
    record,
    questionIndexes,
    surveyLang,
    currentAccountUserId,
    showSelectedOptionEvals,
    onAnswer,
    fullAudit,
    staffUser,
    notes,
    location,
    showRecommendations
}: {
    section: Section;
    record: SurveyVariantRecord;
    questionIndexes: Record<string, string>;
    surveyLang: Survey.Lang;
    currentAccountUserId?: string;
    location?: Location;
    showSelectedOptionEvals?: boolean;
    onAnswer?: (answer: QuestionAnswer) => void;
    fullAudit?: EnrichedFullAudit;
    staffUser?: StaffUser;
    notes?: FullAudit.SurveyRecordNote[];
    showRecommendations?: boolean;
}): JSX.Element {
    return (
        <>
            {section.questions
                ?.filter(question => !record || query(question).isIncludedWithVariant(record))
                .map(question => (
                    <li data-id="survey-question" key={question.id}>
                        <SurveyQuestion
                            question={question}
                            record={record}
                            onAnswer={onAnswer}
                            questionIndexes={questionIndexes}
                            surveyLang={surveyLang}
                            readOnly={!onAnswer}
                            showSelectedOptionEvals={showSelectedOptionEvals}
                            location={location}
                            fullAudit={fullAudit}
                            currentAccountUserId={currentAccountUserId}
                            staffUser={staffUser}
                            notes={notes}
                            showFilter={showRecommendations}
                        />
                    </li>
                ))}
        </>
    );
}

export default ({
    section,
    surveyLang,
    surveySections,
    ...props
}: {
    section: Section;
    record: SurveyVariantRecord;
    questionIndexes: Record<string, string>;
    surveyLang: Survey.Lang;
    surveySections: Section[];
    fullAudit?: EnrichedFullAudit;
    currentAccountUserId?: string;
    location?: Location;
    showSelectedOptionEvals?: boolean;
    onAnswer?: (answer: QuestionAnswer) => void;
    staffUser?: StaffUser;
    notes?: FullAudit.SurveyRecordNote[];
    showRecommendations?: boolean;
}) => {
    const description = useMemo(() => getSectionDescription(surveySections, section.id, surveyLang), [section, surveyLang, surveySections]);

    return (
        <SurveySectionTemplate description={description}>
            <SectionContent section={section} surveyLang={surveyLang} {...props} />
        </SurveySectionTemplate>
    );
};
