import { App, SupportedLanguageCode } from "@vaultinum/vaultinum-api";
import Stripe from "stripe";
import { CreditCardIcon, CustomCardProps, SepaIcon } from "../../design-system";
import { formatAsCurrency } from "../../helpers";
import { CommonLang, DEFAULT_LANG } from "../../lang";
import { PriceInfo } from "../services";

export function getPaymentMethodDetails(method: App.PaymentMethod, lang: CommonLang): Pick<CustomCardProps, "icon" | "label"> {
    switch (method) {
        case App.PaymentMethod.CARD:
            return {
                icon: CreditCardIcon,
                label: lang.payment.payment.creditCard.name
            };
        case App.PaymentMethod.SEPA_DEBIT:
            return { icon: SepaIcon, label: lang.payment.payment.sepaDebit.name };
        default:
            throw new Error(`Unknown payment method: ${method}`);
    }
}

export enum BillingDetailsErrorKeys {
    WRONG_VAT_FORMAT = "WRONG_VAT_FORMAT",
    TAXID_ALREADY_REGISTERED = "TAXID_ALREADY_REGISTERED"
}

export function getBillingDetailsErrorMessage(errorCode: string | undefined, lang: CommonLang) {
    switch (errorCode) {
        case BillingDetailsErrorKeys.WRONG_VAT_FORMAT:
            return lang.payment.billing.wrongVatFormat;
        case BillingDetailsErrorKeys.TAXID_ALREADY_REGISTERED:
            return lang.payment.billing.taxIdRegistered;
        default:
            return lang.shared.saveErrorMessage;
    }
}

export function getProductList(lang: CommonLang): PriceInfo[] {
    return [
        {
            typeKey: App.PricingPlans.ESCROW_ACCESS_CLAUSE,
            title: lang.price.title.accessClause,
            ctaText: lang.price.getStarted,
            features: [
                {
                    included: true,
                    text: lang.price.features.encryptedDeposit
                },
                {
                    included: true,
                    text: lang.price.features.certificateOfDeposit
                },
                {
                    included: true,
                    text: lang.price.features.freeUpdates
                },
                {
                    included: true,
                    text: lang.price.features.contractTemplate
                },
                {
                    included: false,
                    text: lang.price.features.beneficiaryDashboardAccess
                },
                {
                    included: false,
                    text: lang.price.features.legalTermsAtYourService
                },
                {
                    included: false,
                    text: lang.price.features.vaultinumAsSignator
                }
            ]
        },
        {
            typeKey: App.PricingPlans.ESCROW_BIPARTITE,
            title: lang.price.title.bipartite,
            ctaText: lang.price.getStarted,
            features: [
                {
                    included: true,
                    text: lang.price.features.encryptedDeposit
                },
                {
                    included: true,
                    text: lang.price.features.certificateOfDeposit
                },
                {
                    included: true,
                    text: lang.price.features.freeUpdates
                },
                {
                    included: true,
                    text: lang.price.features.contractTemplate
                },
                {
                    included: true,
                    text: lang.price.features.beneficiaryDashboardAccess
                },
                {
                    included: false,
                    text: lang.price.features.legalTermsAtYourService
                },
                {
                    included: false,
                    text: lang.price.features.vaultinumAsSignator
                }
            ]
        },
        {
            typeKey: App.PricingPlans.ESCROW_TRIPARTITE,
            title: lang.price.title.tripartite,
            ctaText: lang.price.getStarted,
            features: [
                {
                    included: true,
                    text: lang.price.features.encryptedDeposit
                },
                {
                    included: true,
                    text: lang.price.features.certificateOfDeposit
                },
                {
                    included: true,
                    text: lang.price.features.freeUpdates
                },
                {
                    included: true,
                    text: lang.price.features.contractTemplate
                },
                {
                    included: true,
                    text: lang.price.features.beneficiaryDashboardAccess
                },
                {
                    included: true,
                    text: lang.price.features.legalTermsAtYourService
                },
                {
                    included: true,
                    text: lang.price.features.vaultinumAsSignator
                }
            ]
        },
        {
            typeKey: App.PricingPlans.DEPOSIT_STANDARD,
            title: lang.price.title.deposit,
            ctaText: lang.price.getStarted,
            features: [
                {
                    included: true,
                    text: lang.price.features.encryptedDeposit
                },
                {
                    included: true,
                    text: lang.price.features.certificateOfDeposit
                }
            ]
        },
        {
            typeKey: App.PricingPlans.DEPOSIT_UPDATE,
            title: lang.price.title.depositUpdate
        },
        {
            typeKey: App.PricingPlans.DEPOSIT_VERIFIED,
            title: lang.price.title.elementCheckedDeposit
        },
        {
            typeKey: App.PricingPlans.DEPOSIT_CONTROLLED,
            title: lang.price.title.contentCheckedDeposit
        },
        {
            typeKey: App.PricingPlans.DEPOSIT_UNLIMITED,
            title: lang.price.title.depositUnlimitedPlan
        },
        {
            typeKey: App.PricingPlans.FULL_AUDIT,
            title: lang.price.title.oneShot,
            ctaText: lang.price.priceOnDemand,
            features: [
                {
                    included: true,
                    text: lang.price.features.oneOnlineAssessment
                },
                {
                    included: true,
                    text: lang.price.features.fiveSurveys
                },
                {
                    included: true,
                    text: lang.price.features.multiUserAccess
                },
                {
                    included: true,
                    text: lang.price.features.sourceCodeScan
                },
                {
                    included: true,
                    text: lang.price.features.expertAnalysis
                },
                {
                    included: true,
                    text: lang.price.features.illustratedReport
                },
                {
                    included: true,
                    text: lang.price.features.recommendations
                },
                {
                    included: false,
                    text: lang.price.features.multipleProducts
                },
                {
                    included: false,
                    text: lang.price.features.multipleCompanies
                }
            ]
        }
    ];
}

export function addPriceToProduct(priceInfo: PriceInfo, app: string, lang: CommonLang, locale: SupportedLanguageCode = DEFAULT_LANG, price?: Stripe.Price) {
    if (app !== App.AppNames.FULL_AUDIT && price) {
        priceInfo.unit_amount = price.unit_amount || 0;
        priceInfo.price = buildReadablePrice(price, lang, locale);
    } else {
        priceInfo.price = lang.price.priceOnDemand;
    }
    priceInfo.groupTitle = null;
    return priceInfo;
}

function buildFrequencyLabel(entryText: string, lang: CommonLang, recurringInterval?: Stripe.Price.Recurring.Interval): string {
    if (recurringInterval) {
        return `${entryText}/${lang.payment.frequency[recurringInterval]}`;
    }
    return entryText;
}

function buildReadablePrice(price: Stripe.Price, lang: CommonLang, locale: SupportedLanguageCode = DEFAULT_LANG): string {
    const formattedCurrency = formatAsCurrency((price.unit_amount || 0) / 100, locale);
    return buildFrequencyLabel(formattedCurrency, lang, price.recurring?.interval);
}

export function getPriceLabel(priceInfos: PriceInfo[], productName: string, lang: CommonLang, recurring?: Stripe.Price.Recurring.Interval): string {
    const label = priceInfos.find(element => element.typeKey === productName)?.title || lang.payment.payment.price;
    return buildFrequencyLabel(label, lang, recurring);
}
