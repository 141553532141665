import classNames from "classnames";
import { CheckCircleIcon } from "../../../../common";

export default function SurveyListItem({
    imageUrl,
    surveyKey,
    title,
    isSelected,
    onClick
}: {
    imageUrl: string;
    surveyKey: string;
    title: string;
    isSelected?: boolean;
    onClick?: (surveyKey: string) => void;
}): JSX.Element {
    function onSelect(): void {
        onClick?.(surveyKey);
    }

    return (
        <div
            onClick={onSelect}
            className={classNames("flex w-full items-center space-x-5 rounded-md border border-neutral-300 bg-white pr-5 shadow-xl", {
                "border-success ring-1 ring-inset ring-success": isSelected,
                "cursor-pointer": !!onClick
            })}
        >
            <img title="cover" src={imageUrl} className="h-14 rounded-l-md" />
            <span className="grow truncate text-base" title={title}>
                {title}
            </span>
            {isSelected && <CheckCircleIcon color="green" />}
        </div>
    );
}
