import {
    calculateSectionScoreSummary,
    calculateSurveyScoreImpact,
    calculateVariantRecord,
    FullAudit,
    getQuestionScores,
    getScoreImpactEvaluation,
    getTagsFromQuestions,
    isDefined,
    query,
    Question,
    ScoreImpactEvaluation,
    Section,
    Survey,
    SurveyRecord,
    SurveyReport,
    SurveyVariantRecord
} from "@vaultinum/vaultinum-api";
import { differenceBy, groupBy, intersection, isEqual, orderBy, range, uniq, uniqBy } from "lodash";

export type EnrichedQuestion = Question & {
    key?: string;
    isObsolete?: boolean;
    hasBeenUpdated?: boolean;
    liveRecord?: SurveyVariantRecord;
    evaluation?: ScoreImpactEvaluation;
    questionScore?: number;
    answerScore?: number;
    delta?: number;
    scope?: string;
};

export type ScopedSurveyVersions = { [key in FullAudit.Scope | "OTHER"]: Survey.Version[] };

export const TRADEMARK_CLASSES = range(1, 45).map((i: number) => ({ value: String(i), label: `Class ${i}` }));
export type QuestionsBySurveyKey = {
    [surveyKey: string]: {
        questions: EnrichedQuestion[];
        surveyRecord: SurveyVariantRecord;
        surveyLang: Survey.Lang;
        surveyVersion: Survey.Version;
        liveRecord?: SurveyVariantRecord;
        scope?: string;
    };
};

export type QuestionWithInitialAnswer = Question & { initialAnswer?: number[] };

export enum RecommendationStatus {
    PENDING_UPDATE = "pending_update",
    UPDATED = "updated",
    OBSOLETE = "obsolete"
}

export const ALL_SURVEYS = "all";

export const EXTENSION_LIST = [
    ".ae",
    ".asia",
    ".at",
    ".au",
    ".be",
    ".bg",
    ".br",
    ".ca",
    ".capital",
    ".ch",
    ".cl",
    ".cn",
    ".co.at",
    ".co.il",
    ".co.in",
    ".co.jp",
    ".co.kr",
    ".co.nz",
    ".co.uk",
    ".co.za",
    ".com",
    ".com.ar",
    ".com.au",
    ".com.br",
    ".com.gr",
    ".com.hk",
    ".com.my",
    ".com.ph",
    ".com.pl",
    ".com.pt",
    ".com.qa",
    ".com.ru",
    ".com.sa",
    ".com.sg",
    ".com.tn",
    ".com.tr",
    ".com.tw",
    ".com.ua",
    ".cz",
    ".de",
    ".dk",
    ".ee",
    ".enterprises",
    ".es",
    ".eu",
    ".eu",
    ".fi",
    ".finance",
    ".financial",
    ".fr",
    ".gr",
    ".group",
    ".hk",
    ".hu",
    ".id",
    ".ie",
    ".in",
    ".industries",
    ".info",
    ".insure",
    ".international",
    ".investments",
    ".it",
    ".jp",
    ".kr",
    ".la",
    ".li",
    ".lt",
    ".lu",
    ".luxe",
    ".luxury",
    ".lv",
    ".ma",
    ".management",
    ".mobile",
    ".money",
    ".mx",
    ".my",
    ".net",
    ".network",
    ".nl",
    ".no",
    ".org",
    ".partners",
    ".ph",
    ".pl",
    ".productions",
    ".properties",
    ".property",
    ".pt",
    ".realestate",
    ".ro",
    ".ru",
    ".se",
    ".services",
    ".sg",
    ".shop",
    ".shopping",
    ".si",
    ".site",
    ".software",
    ".solar",
    ".solutions",
    ".space",
    ".sport",
    ".sports",
    ".store",
    ".sydney",
    ".systems",
    ".tech",
    ".technology",
    ".tn",
    ".tools",
    ".top",
    ".toys",
    ".trade",
    ".trading",
    ".tv",
    ".tw",
    ".ua",
    ".ventures"
].map(ext => ({ value: ext, label: ext }));

/**
 * It takes a section and a path and returns the path to the section
 * @param {Section} section - The section you want to get the path for.
 * @param {number[]} path - The path to the section.
 * @param {Section[]} [sections] - The sections to search through.
 * @returns The path to the section in the sections array.
 */
export function getSectionPath(section: Section, path: number[], sections?: Section[]): number[] {
    if (!sections) {
        return [];
    }
    const sectionIndex = sections.indexOf(section);
    if (sectionIndex >= 0) {
        return [...path, sectionIndex + 1];
    }
    const item = sections.map((subSection, index) => getSectionPath(section, [...path, index + 1], subSection.sections)).find(subPath => subPath.length > 0);
    if (item) {
        return item;
    }
    return [];
}

/**
 * Loop through the questions array and return the first question that matches the questionId.
 * @param {Question[] | undefined} questions - Question[] | undefined
 * @param {string} questionId - The id of the question you want to find
 * @returns A question with the given id.
 */
export function getQuestionFromId(questions: Question[] | undefined, questionId: string): Question | undefined {
    if (!questions) {
        return undefined;
    }
    for (const question of questions) {
        if (question.id === questionId) {
            return question;
        }
        for (const option of question.options) {
            const questionMatch = getQuestionFromId(option.questions, questionId);
            if (questionMatch) {
                return questionMatch;
            }
        }
    }
    return undefined;
}

/**
 * Find the section and question that matches the given questionId.
 * @param {Section[]} sections - Section[] = []
 * @param {string} questionId - The id of the question you want to find
 * @returns { question: Question, section: Section } | undefined
 */
// eslint-disable-next-line @typescript-eslint/default-param-last
export function getSectionQuestionFromId(sections: Section[] | undefined, questionId: string): { section: Section; question: Question } | undefined {
    if (!sections) {
        return undefined;
    }
    for (const section of sections) {
        let questionMatch: Question | { section: Section; question: Question } | undefined = getQuestionFromId(section.questions, questionId);
        if (questionMatch) {
            return { question: questionMatch, section };
        }
        questionMatch = getSectionQuestionFromId(section.sections, questionId);
        if (questionMatch) {
            return { question: questionMatch.question, section: questionMatch.section };
        }
    }
    return undefined;
}

/**
 * Returns the currentSection or the first section of the survey
 * @param surveyVersion - The survey version that you want to get the active section for.
 * @param {Section} [currentSection] - The section that the user is currently viewing.
 * @returns The first section of the first section of the survey version.
 */
export function getActiveSection(surveyVersion: Survey.Version, currentSection?: Section) {
    return currentSection ?? (surveyVersion.sections[0].sections?.length ? surveyVersion.sections[0].sections[0] : surveyVersion.sections[0]);
}

export function orderByEvaluationAndDelta(questions: EnrichedQuestion[]) {
    const lowImpactQuestions = questions.filter(question => question.evaluation === ScoreImpactEvaluation.LOW) || [];
    const highImpactQuestions = questions.filter(question => question.evaluation === ScoreImpactEvaluation.HIGH) || [];
    const criticalImpactQuestions = questions.filter(question => question.evaluation === ScoreImpactEvaluation.CRITICAL) || [];

    return [
        ...orderBy(criticalImpactQuestions, "delta", "desc"),
        ...orderBy(highImpactQuestions, "delta", "desc"),
        ...orderBy(lowImpactQuestions, "delta", "desc")
    ];
}

export function groupQuestionsBySurveyKey(
    surveyLangs: Survey.Lang[],
    surveyRecords: SurveyRecord[],
    scopedSurveyVersions: ScopedSurveyVersions,
    options?: {
        affects?: string[];
        status?: RecommendationStatus;
        liveRecords?: SurveyRecord[];
        impactScoreEvaluation?: ScoreImpactEvaluation;
    }
): { surveyTags: { value: string; label: string }[]; questions: QuestionsBySurveyKey } {
    const questionsBySurveyKey: QuestionsBySurveyKey = {};
    let surveyTags: { value: string; label: string }[] = [];
    Object.entries(scopedSurveyVersions).forEach(([scope, surveyVersions]) => {
        surveyVersions.forEach(surveyVersion => {
            const surveyLang = surveyLangs.find(sl => sl.surveyKey === surveyVersion.surveyKey && sl.surveyVersion === surveyVersion.version);
            if (surveyLang) {
                const surveyRecord = surveyRecords.find(
                    record => record.surveyKey === surveyVersion.surveyKey && record.surveyVersion === surveyVersion.version
                );
                let questions: EnrichedQuestion[] = [];
                let liveRecord: SurveyVariantRecord | undefined;
                if (surveyRecord) {
                    const surveyVariantRecord = {
                        ...surveyRecord,
                        ...calculateVariantRecord(surveyVersion, surveyRecord)
                    };
                    questions = query(surveyVersion)
                        .eval(surveyVariantRecord)
                        .getQuestionsWithEvaluation([Question.Evaluation.Critical, Question.Evaluation.High]);
                    surveyTags = [
                        ...surveyTags,
                        ...uniq(getTagsFromQuestions(questions))
                            .filter(tag => !!tag && !!surveyLang?.evaluationTag[tag]?.name)
                            .map(tag => ({ value: tag, label: surveyLang?.evaluationTag[tag]?.name || tag }))
                    ];

                    if (options?.affects?.length) {
                        // Filter questions by tag
                        questions = questions.filter(
                            question =>
                                question.options.filter(
                                    (option, optionIndex) =>
                                        intersection(
                                            option.evaluations.map(evaluation => evaluation.tag),
                                            options.affects
                                        ).length && surveyVariantRecord.questions[question.id].selectedIndex.some(i => i === optionIndex)
                                ).length
                        );
                    }

                    if (options?.liveRecords) {
                        // Get the record linked to surveyKey + version
                        // If it doesn't exist, get the record linked to the surveyKey
                        const refLiveRecord =
                            options.liveRecords.find(
                                record => record.surveyKey === surveyVersion.surveyKey && record.surveyVersion === surveyVersion.version
                            ) ||
                            options.liveRecords
                                .filter(record => record.surveyKey === surveyVersion.surveyKey)
                                .sort((a, b) => b.creationDate?.getTime() - a.creationDate?.getTime())?.[0];

                        liveRecord = {
                            ...refLiveRecord,
                            ...calculateVariantRecord(surveyVersion, refLiveRecord)
                        };

                        questions = questions.map(question => ({
                            ...question,
                            key: surveyVersion.surveyKey,
                            isObsolete: !liveRecord?.questions?.[question.id],
                            hasBeenUpdated: !!(
                                liveRecord?.questions?.[question.id] &&
                                !isEqual(liveRecord.questions[question.id].selectedIndex, surveyRecord.questions[question.id].selectedIndex)
                            ),
                            liveRecord
                        }));
                    }

                    //add score calculation data
                    const referentImpactScore = calculateSurveyScoreImpact(surveyVersion);
                    const surveyRecordScoreImpact = calculateSurveyScoreImpact(surveyVersion, surveyVariantRecord);
                    questions = questions.map(question => ({
                        ...question,
                        evaluation: getScoreImpactEvaluation(surveyRecordScoreImpact, referentImpactScore, question.id),
                        questionScore: getQuestionScores(surveyRecordScoreImpact, question.id, referentImpactScore).referentQuestionScore,
                        answerScore: getQuestionScores(surveyRecordScoreImpact, question.id, referentImpactScore).answeredQuestionScore,
                        delta: getQuestionScores(surveyRecordScoreImpact, question.id, referentImpactScore).delta
                    }));

                    if (options?.impactScoreEvaluation) {
                        questions = questions.filter(question => question.evaluation === options.impactScoreEvaluation);
                    }

                    if (options?.status) {
                        switch (options.status) {
                            case RecommendationStatus.PENDING_UPDATE:
                                questions = questions.filter(q => !q.isObsolete && !q.hasBeenUpdated);
                                break;
                            case RecommendationStatus.UPDATED:
                                questions = questions.filter(q => !q.isObsolete && q.hasBeenUpdated);
                                break;
                            case RecommendationStatus.OBSOLETE:
                                questions = questions.filter(q => q.isObsolete);
                                break;
                            default:
                                break;
                        }
                    }

                    questionsBySurveyKey[surveyVersion.surveyKey] = {
                        liveRecord,
                        questions: questions.map(question => ({
                            ...question,
                            key: surveyVersion.surveyKey,
                            scope
                        })),
                        surveyRecord: surveyVariantRecord,
                        surveyLang,
                        surveyVersion,
                        scope
                    };
                }
            }
        });
    });
    return { surveyTags: uniqBy(surveyTags, "value"), questions: questionsBySurveyKey };
}

export function isSectionPartOfPreamble(sectionId: string, surveyVersion: Survey.Version): boolean {
    const preambleSection = surveyVersion.preambleSectionId ? query(surveyVersion).getSectionFromId(surveyVersion.preambleSectionId) : undefined;
    if (!preambleSection) {
        return false;
    }
    return !!query(preambleSection).getSectionFromId(sectionId);
}

export function getSectionDescription(sections: Section[], sectionId: string, surveyLang: Survey.Lang): string | undefined {
    const sectionDescription = surveyLang.sections?.[sectionId]?.description;
    if (sectionDescription) {
        return sectionDescription;
    }
    const parent = sections.find(section => section.sections?.some(subSection => subSection.id === sectionId));
    return parent ? surveyLang.sections?.[parent.id].description : undefined;
}

export function getSurveyRecordLastUpdated(surveyRecord: SurveyRecord): { lastUpdated: Date; userUID: string } | null {
    if (!surveyRecord.questions) {
        return null;
    }
    const sortedQuestions = Object.values(surveyRecord.questions).sort((a, b) => (b.lastUpdated?.getTime() || 0) - (a.lastUpdated?.getTime() || 0));
    if (!sortedQuestions.length) {
        return null;
    }
    const { lastUpdated, userUID } = sortedQuestions[0];
    return {
        lastUpdated,
        userUID
    };
}

// # getSectionCompletionDetails - This function is called on every question answered and requires caching for better performance
type SectionCompletionDetails = { totalQuestions: number; completionPercent: number; allQuestionAnswered: boolean; sectionScore?: number };
const sectionCompleteCache: {
    [surveyRecordKey: string]: SectionCompletionDetails & {
        lastUpdate?: Date;
    };
} = {};
export function getSectionCompletionDetails(section: Section, surveyVariantRecord: SurveyVariantRecord, withScore: boolean): SectionCompletionDetails {
    const lastUpdated = getSurveyRecordLastUpdated(surveyVariantRecord);
    const surveyRecordKey = `${surveyVariantRecord.id}-${section.id}-${withScore}`;
    if (sectionCompleteCache[surveyRecordKey] && sectionCompleteCache[surveyRecordKey].lastUpdate === lastUpdated?.lastUpdated) {
        return sectionCompleteCache[surveyRecordKey];
    }
    const totalQuestions = query(section).getQuestionCount({ surveyVariantRecord });
    const answeredQuestions = query(section).getQuestionCount({ surveyVariantRecord }, true);
    const completionPercent = Math.floor((100 * answeredQuestions) / totalQuestions);
    const result = {
        totalQuestions,
        completionPercent,
        allQuestionAnswered: answeredQuestions === totalQuestions,
        sectionScore: withScore ? Math.floor(calculateSectionScoreSummary(section, surveyVariantRecord)) : undefined
    };
    sectionCompleteCache[surveyRecordKey] = { ...result, lastUpdate: lastUpdated?.lastUpdated };
    return result;
}

// # QuestionCount - this function is often called and requires some local caching
const questionCountCache: { [surveyRecordKey: string]: { lastUpdate?: Date; count: number } } = {};
export function getQuestionCount(surveyVersion: Survey.Version, surveyVariantRecord: SurveyVariantRecord, answeredOnly: boolean): number {
    const lastUpdated = getSurveyRecordLastUpdated(surveyVariantRecord);
    const surveyRecordKey = `${surveyVersion.version}-${surveyVariantRecord.id}-${answeredOnly}`;
    if (questionCountCache[surveyRecordKey] && questionCountCache[surveyRecordKey].lastUpdate === lastUpdated?.lastUpdated) {
        return questionCountCache[surveyRecordKey].count;
    }
    const count = query(surveyVersion).getQuestionCount({ surveyVariantRecord }, answeredOnly);
    questionCountCache[surveyRecordKey] = { count, lastUpdate: lastUpdated?.lastUpdated };
    return count;
}

// # getQuestionIndex - this API function is often called and requires some local caching
const questionIndexesCache: { [surveyVersionKey: string]: Record<string, string> } = {};
export function getQuestionIndexes(surveyVersion: Survey.Version): Record<string, string> {
    const surveyVersionKey = `${surveyVersion.surveyKey}-${surveyVersion.version}`;
    if (questionIndexesCache[surveyVersionKey]) {
        return questionIndexesCache[surveyVersionKey];
    }
    const questionIndexes = query(surveyVersion).getQuestionIndexes();
    questionIndexesCache[surveyVersionKey] = questionIndexes;
    return questionIndexes;
}

export function getAllQuestions(questions: QuestionsBySurveyKey): EnrichedQuestion[] {
    return Object.values(questions).flatMap(questionsByScope => questionsByScope.questions);
}

export function groupQuestionsByScope(questions: QuestionsBySurveyKey): { [scope: string]: EnrichedQuestion[] } {
    return groupBy(
        getAllQuestions(questions).filter(element => element.scope),
        "scope"
    );
}

function countQuestionsByScope(questions: QuestionsBySurveyKey): { [scope: string]: number } {
    const groupedQuestionsByScope = groupQuestionsByScope(questions);
    return Object.keys(groupedQuestionsByScope).reduce((acc, scope) => {
        return {
            ...acc,
            [scope]: groupedQuestionsByScope[scope].length
        };
    }, {});
}

export function groupSurveyVersionsByScopes(surveyVersions: Survey.Version[], surveys: Survey[]): ScopedSurveyVersions {
    return surveyVersions.reduce((acc, surveyVersion) => {
        const foundSurvey = surveys.find(survey => survey.key === surveyVersion.surveyKey);
        if (!foundSurvey?.visible) {
            return acc;
        }
        const scope = foundSurvey?.scope || "OTHER";
        return {
            ...acc,
            [scope]: [...(acc[scope] || []), surveyVersion]
        };
    }, {} as ScopedSurveyVersions);
}

export function getUpdatedAnswersSinceLastReport(
    surveyVariantRecord: SurveyVariantRecord,
    surveyReport: SurveyReport | null,
    orderedQuestions: Question[]
): { questions: QuestionWithInitialAnswer[]; unansweredQuestions: Question[]; count: number; unansweredCount: number } {
    const originalRecord = surveyReport?.surveyRecords.find(record => record.id === surveyVariantRecord.id);
    // get the answers that have changed since the last report
    // then, filter out the same answers as the original report
    const updatedAnswers = Object.values(surveyVariantRecord.questions)
        .filter(deed => surveyReport && deed.lastUpdated.getTime() > surveyReport.creationDate.getTime())
        .filter(deed => !isEqual(deed.selectedIndex, originalRecord?.questions[deed.questionId]?.selectedIndex));

    // filter on questions to keep the order
    const unansweredQuestions = orderedQuestions.filter(question => !query(question).isAnswered(surveyVariantRecord));
    const unansweredCount = unansweredQuestions.reduce((acc, question) => acc + query(question).getQuestionCount({ surveyVariantRecord }), 0);
    const questions = differenceBy(orderedQuestions, unansweredQuestions, "id")
        .map(question => {
            const updatedAnswer = updatedAnswers.find(deed => deed.questionId === question.id && deed.selectedIndex?.length);
            if (updatedAnswer) {
                return {
                    ...question,
                    initialAnswer: originalRecord?.questions[question.id]?.selectedIndex
                };
            }
            return undefined;
        })
        .filter(isDefined);
    return { questions, unansweredQuestions, count: questions.length, unansweredCount };
}

export function countAllAndScopeQuestions(
    surveyLangs: Survey.Lang[],
    surveyRecords: SurveyRecord[],
    scopedSurveyVersions: ScopedSurveyVersions
): {
    all: number;
    scopes: { [scope: string]: number };
} {
    const groupedQuestions = groupQuestionsBySurveyKey(surveyLangs, surveyRecords, scopedSurveyVersions);
    return {
        all: Object.keys(groupedQuestions.questions).flatMap(key => groupedQuestions.questions[key].questions).length,
        scopes: countQuestionsByScope(groupedQuestions.questions)
    };
}
