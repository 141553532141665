import { Row, Table as TanstackTable } from "@tanstack/react-table";
import { ChangeEvent } from "react";
import { Input } from "../Input";
import { TableRow } from "./TableTools";

function selectChildren<T>(isChecked: boolean, row: Row<T>, currentSelection: Record<string, boolean>): Record<string, boolean> {
    let newSelection = { ...currentSelection };
    newSelection[row.id] = isChecked;
    row.subRows.forEach(subRow => {
        newSelection = selectChildren(isChecked, subRow, newSelection);
    });
    return newSelection;
}

export function SelectRowCheckbox<T>({ row, table }: { row: Row<TableRow<T>>; table: TanstackTable<TableRow<T>> }): JSX.Element {
    const meta: { isTree?: boolean } | undefined = table.options.meta;

    function onCheck(event: ChangeEvent<HTMLInputElement>): void {
        if (row.original.disabled) {
            return;
        }
        const isChecked = event.target.checked;
        const currentSelection = table.getState().rowSelection;
        const newSelection = selectChildren(isChecked, row, { ...currentSelection });
        if (meta?.isTree && row.parentId) {
            // In case this is a subrow, handle the parent state
            if (row.parentId) {
                const parentRow = table.getRow(row.parentId);
                if (parentRow) {
                    newSelection[parentRow.id] = parentRow.subRows.every(subRow => newSelection[subRow.id]);
                }
            }
        }
        table.setRowSelection(() => newSelection);
    }

    return (
        <div className="flex h-full items-center justify-center">
            <Input.Checkbox
                isIndeterminate={row.getIsSomeSelected()}
                onChange={onCheck}
                checked={row.getIsSelected() || row.getIsAllSubRowsSelected()}
                disabled={row.original.disabled}
            />
        </div>
    );
}

export function SelectAllHeader<T>({ table }: { table: TanstackTable<TableRow<T>> }): JSX.Element {
    const isAllRowsSelected = table.getRowModel().rows.every(row => row.getIsSelected() || row.original.disabled);
    return (
        <div className="flex w-full justify-center">
            <Input.Checkbox
                isIndeterminate={!isAllRowsSelected && table.getIsSomeRowsSelected()}
                onChange={() => {
                    const rowIds = table
                        .getRowModel()
                        .rows.reduce(
                            (selectedRows, row) => ({ ...selectedRows, [row.index]: row.original.disabled ? row.getIsSelected() : !isAllRowsSelected }),
                            {}
                        );
                    table.setRowSelection(() => rowIds);
                }}
                checked={isAllRowsSelected}
            />
        </div>
    );
}
