/**
 * This service should contain all the shared methods related to the Account information
 * @module services/account
 */
import { ACCOUNT_APP_COLLECTION, ACCOUNT_COLLECTION, ACCOUNT_USER_COLLECTION, Account, AccountUser, App, Company, isDefined } from "@vaultinum/vaultinum-api";
import { pickBy } from "lodash";
import { converter } from "../../helpers";
import { IndexedDataEventSource } from "../tools";
import { doGet, doPost } from "./apiService";
import {
    DocumentData,
    DocumentSnapshot,
    Timestamp,
    and,
    collection,
    doc,
    documentId,
    getDocs,
    getDocsByBatch,
    getFirestore,
    onSnapshot,
    query,
    updateDoc,
    where
} from "./firebaseService";

// constants

export const COMPANY_SIZES: Company.Size[] = ["1-10", "11-50", "51-250", "251-500", "501-1000", "1001-2000", "2000+"];

// accounts

const convertDocToAccount = (docSnapshot: DocumentSnapshot<DocumentData>): Account =>
    ({
        ...docSnapshot.data(),
        id: docSnapshot.id,
        creationDate: docSnapshot.data()?.creationDate?.toDate()
    }) as Account;

const accountEvents = new IndexedDataEventSource<Account, string>((accountId, onUpdate) =>
    onSnapshot(doc(collection(getFirestore(), ACCOUNT_COLLECTION), accountId), querySnapshot => onUpdate(convertDocToAccount(querySnapshot)))
);

export function getAccountsByDomainId(whiteLabelDomainId: string | null, onUpdate: (accounts: Account[]) => void): () => void {
    return onSnapshot(query(collection(getFirestore(), ACCOUNT_COLLECTION), where("whiteLabelDomainId", "==", whiteLabelDomainId)), querySnapshot => {
        onUpdate(querySnapshot.docs.map(convertDocToAccount));
    });
}

export async function getAccountsByIds(accountIds: string[]) {
    const accountQuery = (ids: string[]) => query(collection(getFirestore(), ACCOUNT_COLLECTION), where(documentId(), "in", ids));
    return getDocsByBatch(accountIds, accountQuery, convertDocToAccount);
}

export async function getAccountsByIdsAndDomainId(accountIds: string[], whiteLabelDomainId: string | null | undefined) {
    const accountQuery = (ids: string[]) =>
        query(collection(getFirestore(), ACCOUNT_COLLECTION), where(documentId(), "in", ids), where("whiteLabelDomainId", "==", whiteLabelDomainId ?? null));
    return getDocsByBatch(accountIds, accountQuery, convertDocToAccount);
}

export function getAccount(accountId: string | undefined): Promise<Account | null>;
export function getAccount(accountId: string | undefined, onUpdate: (account: Account | null) => void): () => void;
export function getAccount(accountId: string | undefined, onUpdate?: (account: Account | null) => void): Promise<Account | null> | (() => void) {
    if (onUpdate) {
        if (!accountId) {
            onUpdate(null);
            return () => {};
        }
        return accountEvents.addListener(accountId, onUpdate);
    }

    if (!accountId) {
        return Promise.resolve(null);
    }
    return accountEvents.getData(accountId);
}

export async function getAccountsByEmailAndDomain(email: string, whiteLabelDomainId: string | null): Promise<Account[]> {
    return doGet("account", { email, whiteLabelDomainId });
}

export function updateAccountInfo(account: Account): Promise<void> {
    const { id, companyAddress, ...updatedAccountData } = account;
    const normalizedCompanyAddress = companyAddress ? pickBy(companyAddress, isDefined) : null;

    return updateDoc(doc(collection(getFirestore(), ACCOUNT_COLLECTION), id), {
        ...pickBy(updatedAccountData, isDefined),
        companyAddress: normalizedCompanyAddress
    });
}

export function updateBillingDetails(accountId: string, billingDetails?: Account["billingDetails"]): Promise<void> {
    return doPost(`account/${accountId}/billingDetails`, { billingDetails });
}

// accounts > users

const convertDocToAccountUser = (docSnapshot: DocumentSnapshot<DocumentData>): AccountUser => {
    const data = docSnapshot.data() as Omit<AccountUser, "id" | "creationDate"> & { creationDate: Timestamp };
    return {
        ...data,
        id: docSnapshot.id,
        creationDate: data?.creationDate.toDate()
    };
};

const accountUsersEvents = new IndexedDataEventSource<AccountUser[], string>((accountId, onUpdate) =>
    onSnapshot(collection(getFirestore(), ACCOUNT_COLLECTION, accountId, ACCOUNT_USER_COLLECTION), querySnapshot =>
        onUpdate(querySnapshot.docs.map(convertDocToAccountUser))
    )
);

export function getAccountUser(account: Account, uid: string): Promise<AccountUser | null>;
export function getAccountUser(account: Account, uid: string, onUpdate: (accountUsers: AccountUser | null) => void): () => void;
export function getAccountUser(
    account: Account,
    uid: string,
    onUpdate?: (accountUsers: AccountUser | null) => void
): Promise<AccountUser | null> | (() => void) {
    if (onUpdate) {
        return accountUsersEvents.addListener(account.id, accountUsers => onUpdate(accountUsers.find(({ id }) => id === uid) ?? null));
    }
    return accountUsersEvents.getData(account.id).then(accountUsers => accountUsers.find(({ id }) => id === uid) ?? null);
}

export function getAccountUsers(account: Account | null | undefined): Promise<AccountUser[]>;
export function getAccountUsers(account: Account | null | undefined, onUpdate: (accountUsers: AccountUser[]) => void): () => void;
export function getAccountUsers(account: Account | null | undefined, onUpdate?: (accountUsers: AccountUser[]) => void): Promise<AccountUser[]> | (() => void) {
    if (onUpdate) {
        if (!account) {
            return () => {};
        }
        return accountUsersEvents.addListener(account.id, onUpdate);
    }
    return account ? accountUsersEvents.getData(account.id) : Promise.resolve([]);
}

export function getAccountUserByAccountId(accountId: string, uid: string, onUpdate: (accountUser: AccountUser | null) => void): () => void {
    if (!accountId) {
        return () => {};
    }
    return accountUsersEvents.addListener(accountId, accountUsers => onUpdate(accountUsers.find(({ id }) => id === uid) ?? null));
}

export async function getAccountsWithTag(tag: Account.Tag, whiteLabelDomainId: string | null = null): Promise<Account[]> {
    const accountDocs = await getDocs(
        query(
            collection(getFirestore(), ACCOUNT_COLLECTION),
            and(where("whiteLabelDomainId", "==", whiteLabelDomainId), where("tags", "array-contains", tag))
        ).withConverter(converter<Account>())
    );
    return accountDocs.docs.map(accountDoc => accountDoc.data());
}

// accounts > apps

const convertDocToApp = (docSnapshot: DocumentSnapshot<DocumentData>): Account.AppSettings => ({
    ...(docSnapshot.data() as Omit<Account.AppSettings, "code">),
    code: docSnapshot.id as App.Code
});

export function getAccountAppSettings(accountId: string | undefined, onUpdate: (apps: Account.AppSettings[]) => void): () => void {
    if (!accountId) {
        return () => {};
    }
    return onSnapshot(collection(getFirestore(), ACCOUNT_COLLECTION, accountId, ACCOUNT_APP_COLLECTION), queryDoc =>
        onUpdate(queryDoc.docs.map(convertDocToApp))
    );
}

// accounts > partners

export async function invitePartner(email: string, accountId: string, notifyByEmail: boolean): Promise<void> {
    return doPost("partner/invite", {
        email,
        accountId,
        notifyByEmail
    });
}
