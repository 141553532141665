import SurveySectionDescription from "./SurveySectionDescription";

export default function SurveySectionTemplate({ children, description }: { children: React.ReactNode; description?: string }): JSX.Element {
    return (
        <>
            {description && <SurveySectionDescription description={description} />}
            <ul className="section-questions-wrapper space-y-4 p-3">{children}</ul>
        </>
    );
}
