import { Column } from "@tanstack/react-table";
import { useVirtualizer, VirtualItem } from "@tanstack/react-virtual";
import { intersection, partition, uniq } from "lodash";
import { ReactNode, useEffect, useMemo, useRef, useState } from "react";
import { ColumnFilter, EMPTY_VALUES, Tooltip } from "..";
import { useLang } from "../../../lang";
import { Input } from "../Input/Input";

type CustomRow = { getValue: () => boolean; getLabel: () => string };
type CustomFilter<T extends object> = ColumnFilter<T> & { getValue: () => unknown; getLabel: () => string };

function isCustomRow(value: unknown): value is CustomRow {
    return typeof value === "object" && value !== null && "getLabel" in value;
}

function isCustomFilter<T extends object>(value: ReactNode | CustomFilter<T> | ColumnFilter<T>): value is CustomFilter<T> {
    return typeof value === "object" && value !== null && "getValue" in value && "onFilter" in value;
}

function FilterLine({
    value,
    label,
    existingFilters,
    onFilter
}: {
    value: unknown;
    label: ReactNode;
    existingFilters: unknown[];
    onFilter: (value: unknown, isActive: boolean) => void;
}): JSX.Element {
    const lang = useLang();
    const textComponentRef = useRef<HTMLSpanElement>(null);
    const [tooltipTitle, setTooltipTitle] = useState("");
    // Set tooltip only if text is truncated
    useEffect(() => {
        if (textComponentRef.current && textComponentRef.current.offsetWidth < textComponentRef.current.scrollWidth) {
            setTooltipTitle(textComponentRef.current.innerText);
        }
    }, [textComponentRef.current?.offsetWidth, textComponentRef.current?.scrollWidth]);

    const isChecked = Array.isArray(value) ? !!intersection([...existingFilters, EMPTY_VALUES], value).length : existingFilters.includes(value);

    return (
        <Tooltip color="white" title={tooltipTitle} delayDuration="long">
            <label className="flex w-full cursor-pointer items-center space-x-1">
                <Input.Checkbox onChange={event => onFilter(value, event.target.checked)} checked={isChecked} />
                <span className="truncate" ref={textComponentRef}>
                    {label ? label.toString() : `<${lang.shared.empty}>`}
                </span>
            </label>
        </Tooltip>
    );
}

function searchFilter<T extends object>(element?: ColumnFilter<T> | CustomFilter<T>, searchValue?: string): boolean {
    const value = isCustomFilter(element) ? element.getValue() : element?.value;
    const label = isCustomFilter(element) ? element.getLabel() : element?.label;
    return (
        !searchValue ||
        !!element?.toString().toLowerCase().includes(searchValue.toLowerCase()) ||
        !!value?.toString().toLowerCase().includes(searchValue.toLowerCase()) ||
        !!label?.toLowerCase().includes(searchValue.toLowerCase())
    );
}

export function Filters<T extends object>({
    column,
    existingFilters,
    onFilter,
    onClear,
    onSelectAll
}: {
    column: Column<T, unknown> & { filters?: ColumnFilter<T>[] };
    existingFilters: unknown[];
    onFilter: (value: unknown, isActive: boolean) => void;
    onClear: () => void;
    onSelectAll: (values: unknown[]) => void;
}) {
    const listContainerRef = useRef<HTMLDivElement>(null);
    const [search, setSearch] = useState<string>();
    const lang = useLang();

    const sortedUniqueValues: (ReactNode | CustomFilter<T>)[] = useMemo(() => {
        if (column.filters) {
            return column.filters
                .map(filter => ({
                    ...filter,
                    getLabel: () => filter.label,
                    getValue: () => filter.value
                }))
                .filter(elem => searchFilter<T>(elem, search));
        } else {
            let uniqueValues = Array.from(column.getFacetedUniqueValues().keys())
                .flat()
                .sort()
                .filter(elem => searchFilter<T>(elem, search));
            // Replace all empty values with null
            if (uniqueValues.some(value => EMPTY_VALUES.includes(value))) {
                uniqueValues = [...uniqueValues.filter(value => !EMPTY_VALUES.includes(value)), null];
            }
            const [filteredValues, notFilteredValues] = partition(uniqueValues, item => existingFilters.includes(item));
            return uniq([...filteredValues, ...notFilteredValues]);
        }
        // existingFilters is not a dependency because we don't want to sort the list when the user selects a filter
    }, [column, search]);

    const rowVirtualizer = useVirtualizer({
        count: sortedUniqueValues.length,
        getScrollElement: () => listContainerRef.current,
        estimateSize: () => 25,
        overscan: 5
    });

    const totalSize = rowVirtualizer.getTotalSize();
    const virtualRows = rowVirtualizer.getVirtualItems();

    const paddingTop = virtualRows?.[0]?.start || 0;
    const paddingBottom = virtualRows.length ? totalSize - (virtualRows[virtualRows.length - 1]?.end || 0) : 0;

    function renderFilters(virtualItem: VirtualItem): JSX.Element {
        const row = sortedUniqueValues[virtualItem.index];
        if (isCustomRow(row)) {
            return <FilterLine value={row.getValue()} label={row.getLabel()} existingFilters={existingFilters} onFilter={onFilter} />;
        }
        return <FilterLine value={row} label={row as ReactNode} existingFilters={existingFilters} onFilter={onFilter} />;
    }

    return (
        <div className="space-y-4">
            <div>
                <div className="font-semibold">{lang.table.filter}</div>
                <Input.Search value={search} onChange={event => setSearch(event.target.value)} placeholder={lang.table.search} />
            </div>
            <div className="space-y-2">
                <div>
                    <div className="flex gap-1">
                        <label className="flex cursor-pointer items-center space-x-1">
                            <Input.Checkbox
                                onChange={event =>
                                    onSelectAll(event.target.checked ? sortedUniqueValues.map(value => (isCustomFilter(value) ? value.getValue() : value)) : [])
                                }
                                checked={!!sortedUniqueValues.length && existingFilters.length === sortedUniqueValues.length}
                            />
                            <div className="text-slate-dark">{lang.table.selectAll}</div>
                        </label>
                    </div>
                </div>
                <div className="rounded-md border border-grey-extra-light p-2">
                    <div ref={listContainerRef} className="relative max-h-32 min-h-15 overflow-auto" style={{ height: totalSize }}>
                        {!!paddingTop && <div style={{ height: paddingTop }} />}
                        {virtualRows.map((virtualItem: VirtualItem) => {
                            return (
                                <div
                                    key={virtualItem.key}
                                    data-index={virtualItem.index}
                                    ref={rowVirtualizer.measureElement}
                                    className="absolute top-0 left-0 flex w-full items-center gap-1"
                                    style={{ transform: `translateY(${virtualItem.start}px)` }}
                                >
                                    {renderFilters(virtualItem)}
                                </div>
                            );
                        })}
                        {!!paddingBottom && <div style={{ height: paddingBottom }} />}
                    </div>
                </div>
                <div onClick={onClear} className="cursor-pointer text-slate-dark underline" children={lang.table.clearAll} />
            </div>
        </div>
    );
}
